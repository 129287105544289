import _ from "underscore";
import Backbone from "backbone";
import jquery from "jquery";
import Isotope from "isotope-layout"
import projectsItem from "./projectsItem";


const projectsList = Backbone.View.extend({
    el: ".ProjectsList",
    events: {
        "click .workFilter a": "filterItems",
    },
    initialize: function (t) {
        console.log("projectsList");
        this.filterClicked=false;
        this.options = t || {}, this.$(".ProjectsItem").each((function (t, e) {
            new projectsItem({
                el: this,
                ind: t
            })
        }))
        this.iso = new Isotope( '.ProjectsList-wrapper-inner', {
            itemSelector: '.ProjectsList-wrapper-inner .ProjectsItem',
            percentPosition: true,
            masonry: {
                columnWidth:'.ProjectsList-wrapper-inner .columnWidth',
                gutter:'.ProjectsList-wrapper-inner .gutter'
              }
        });
        // console.log(jquery( '.ProjectsItem:visible')[0])
        jquery( '.ProjectsList-wrapper-inner .ProjectsItem').each(function(){
            jquery(this).attr("index",jquery(this).index())
        })
        Backbone.View.prototype.initialize.call(this, t)
    },
    filterItems: function (t) {
       if(!this.filterClicked){
            jquery( '.ProjectsList-wrapper-inner .ProjectsItem').each(function(){
                jquery(this).addClass('show')
                jquery(this).find('.ProjectsList-wrapper-inner .ProjectsItem-imageClipper').addClass('remove-clipping')
                var img = jquery(this).find('.ProjectsList-wrapper-inner .ProjectsItem-imageClipper img');
                var src = img.data('src');
                img.attr('src',src)
                 
            })
            this.filterClicked=true
       }
        var filtera = jquery(t.currentTarget);
        var filterli = filtera.closest('li')
        jquery('.ProjectsList .workFilter li').not(filterli).removeClass('active')
        filterli.addClass('active')
        var filter = filtera.data('filter');
        //console.log('.filter-'+filter)
        this.iso.arrange({ filter: filter==='*'?"*":'.filter-'+filter })
       
    }
})

export default projectsList