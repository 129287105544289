import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import scrollTrigger from "./scrollTrigger.js";
// var i = n(6),
//                 r = n(1),
//                 o = n(12);
const baseThree = Backbone.View.extend(_.extend({}, Backbone.Events, {
                initialize: function(t) {
                    // console.log("baseThree",this.el)
                    this.options = t || {}, this.views = {}, !1 === this.options.server && this.setElement(jQuery(""))
                },
                isRendered: function() {
                    return !!this.$el.length
                },
                $parent: function() {
                    return null
                },
                attach: function() {
                    var t = this.$parent();
                    t && t.append(this.$el)
                },
                renderData: function() {
                    return this.data || {}
                },
                render: function() {
                    var t = this.renderData();
                    //console.log(this);
                    return t.viewOptions = this.options, 
                    // this.setElement(this.template.render(t)), 
                    this.attach(), this
                },
                activate: function() {
                    return this.viewName && jQuery("html").attr("data-activeView", this.viewName), 
                    this.loadData().then(function() {
                        this.isRendered() && 0 != this.$el.parents("html").length || this.render(), this.viewsRegistration()
                    }.bind(this)).then(function() {
                        var t = scrollTrigger.createEmptyPromise();
                        return this.views && (t = Promise.all(_.map(this.views, (function(t) {
                            return typeof t.activate === 'undefined' ? scrollTrigger.createEmptyPromise() : t.activate()
                        })))), t
                    }.bind(this))
                },
                deactivate: function(t) {
                    t = t || {};
                    var e = scrollTrigger.createEmptyPromise();
                    return this.views && (e = Promise.all(_.map(this.views, (function(e) {
                        return e.deactivate(t)
                    })))), e.then(function() {
                        t.destroy && this.destroy()
                    }.bind(this))
                },
                destroy: function() {
                    this.undelegateEvents(), this.$el.removeData().unbind(), this.remove()
                },
                viewsRegistration: function() {
                    var t = this.VIEWS_TO_REGISTER || {};
                    _.each(t, function(t, n) {
                        this.$(t.selector).each(function(i, r) {
                            
                            if (!jQuery(r).closest(".Section-content").length || !this.$el.hasClass("Section")) {
                                
                                var o = {
                                    el: r
                                };
                                this.registerView(n + (i ? " " + i : ""), new t.viewConstructor(o))
                            }
                        }.bind(this))
                    }.bind(this))
                },
                registerView: function(t, e) {
                    return e.parent = this, this.views[t] = e, e
                },
                addView: function(t) {
                    this.views.push(t)
                },
                getView: function(t) {
                    return this.views[t]
                },
                destroyView: function(t) {
                    this.views[t].destroy(), delete this.views[t]
                },
                proceedDataOnLoad: function(t) {
                    this.data = t
                },
                onDataLoadStart: function() {},
                onDataLoadEnd: function() {},
                forceLoadData: function(t) {
                    var n, i = this;
                    return (t = t || this.apiUrl) ? (n = r.isFunction(t) ? t() : t, i.onDataLoadStart(), Promise.resolve(e.getJSON(n).then(function(t) {
                        this.proceedDataOnLoad(t), i.onDataLoadEnd()
                    }.bind(this)).fail((function() {
                        console.log("Data load error"), i.onDataLoadEnd(), app.router.navigateBack()
                    })))) : scrollTrigger.createEmptyPromise()
                },
                loadData: function() {
                    var t = _.isFunction(this.apiUrl) ? this.apiUrl() : this.apiUrl;
                    return !this.data && t ? this.disableCache ? this.forceLoadData(t) : void 0 !== window.app.cache[t] ? (this.data = window.app.cache[t], scrollTrigger.createEmptyPromise()) : this.forceLoadData(t).then(function() {
                        window.app.cache[t] = this.data
                    }.bind(this)) : scrollTrigger.createEmptyPromise()
                },
                playIn: function() {
                    return scrollTrigger.createEmptyPromise()
                },
                playOut: function() {
                    return scrollTrigger.createEmptyPromise()
                }
            }))
            export default baseThree