import jQuery from "jquery";
import _ from "underscore"; 
import fastclick from "fastclick";
import Backbone from "backbone";
import topLogo from "./topLogo.js";
import routing  from "./routing.js"
import ajaxSetup from "./ajaxSetup.js";
import scrollTrigger from "./base/scrollTrigger.js";
import base84 from "./base/base84.js";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function() {
        app.settings.isDesktop || fastclick.attach(document.body);
    }, false);
}

// app.configure = s.configure,
// app.configure(),
window.app.vent = _.extend({}, Backbone.Events),
window.app.state = {},
window.app.cache = {}; 
window.app.utils = scrollTrigger;

function f(t, e) {
	e || (e = window.location.href), t = t.replace(/[\[\]]/g, "\\$&");
	var n = new RegExp("[?&]" + t + "(=([^&#]*)|&|#|$)").exec(e);
	return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null
}
app.settings.isDesktop || fastclick.attach(document.body);
var d = f("exclude");
d && d.split(",").forEach((function(t) {
	var e = i("." + t),
		n = e.height();
	i("<div>").height(n).insertAfter(e), e.remove()
}));
app.configure = ajaxSetup.configure, app.configure(), window.app.vent = _.extend({}, Backbone.Events), window.app.state = {}, window.app.cache = {}, window.app.utils = scrollTrigger;
var m = new base84("Graphic-webNormal");
if (
	// Promise.all([m.load()]).then((function() {
	// 	console.log("Fonts loaded"), window.app.state.fontsLoaded = !0, window.app.vent.trigger("fonts-loaded")
	// }), 
	// (function() {
	// 	console.log("Fonts is not available")
	// })), 
	window.app.state = {}, window.app.els = {
		$window: jQuery(window),
		$body: jQuery("body"),
		$htmlBody: jQuery("html,body"),
		$content: jQuery(".Content-body")
	}, 
	jQuery.get("assets/images/svg/sprite.symbol.svg", (function(t) {
		var e = document.createElement("div");
		e.style.display = "none", 
		e.innerHTML = (new XMLSerializer).serializeToString(t.documentElement), 
		document.body.insertBefore(e, document.body.childNodes[0])
	})), 
	app.settings.iOSVersion = 0, /iP(hone|od|ad)/.test(navigator.platform)) {
	var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
	app.settings.iOSVersion = parseInt(v[1], 10)
}
app.settings.ipadModel = function() {
	var t = document.createElement("canvas");
	if (t) {
		var e = t.getContext("webgl") || t.getContext("experimental-webgl");
		if (e) {
			var n = e.getExtension(e.RENDERER);
			if (n) var i = e.getParameter(n.UNMASKED_RENDERER_WEBGL)
		}
	}
	if (window.screen.height / window.screen.width == 1024 / 768)
		if (1 === window.devicePixelRatio) switch (i) {
			default: return "iPad, iPad 2, iPad Mini";
			case "PowerVR SGX 535":
					return "iPad";
			case "PowerVR SGX 543":
					return "iPad 2 or Mini"
		}
	else switch (i) {
		default: return "iPad 3, 4, 5, Mini 2, Mini 3, Mini 4, Air, Air 2";
		case "PowerVR SGX 543":
				return "iPad 3";
		case "PowerVR SGX 554":
				return "iPad 4";
		case "Apple A7 GPU":
				return "iPad Air, Mini 2, Mini 3";
		case "Apple A8X GPU":
				return "iPad Air 2";
		case "Apple A8 GPU":
				return "iPad Mini 4";
		case "Apple A9 GPU":
				return "iPad 5, Pro 9.7"
	}
	else {
		if (window.screen.height / window.screen.width == 1112 / 834) return "iPad Pro 10.5";
		if (window.screen.height / window.screen.width != 1366 / 1024) return "Not an iPad";
		switch (i) {
			default: return "iPad Pro 12.9, Pro 12.9 (2nd Gen)";
			case "Apple A10X GPU":
					return "iPad Pro 12.9 (2nd Gen)";
			case "Apple A9 GPU":
					return "iPad Pro 12.9"
		}
	}
}(), 

app.settings.lazyVideo = "true" == f("lazyVideo"), 
app.router = new routing, 
app.els.$body.on("click", ".u-Route", (function(t) {
	if (!app.els.$body.hasClass("Page404")) {
		r.history.getFragment();
		var e = i(t.target).closest("a").attr("href"),
			n = i(t.currentTarget).attr("href") || e;
		t.preventDefault(), r.history.navigate(n, {
			trigger: "true"
		})
	}
})), 
app.utils.detectVideoAutoplayFeature((function(t) {
	app.settings.canAutoplay = t, 
	app.router.start(), 
	app.settings.isIE && objectFitImages()
})), 
// console.log(app.utils.startRoute)
// app.utils.startRoute((function(t) {
// 	app.settings.canAutoplay = t, 
// 	app.router.start(), 
// 	app.settings.isIE && objectFitImages()
// })), 

app.views = {}, 
jQuery(".TopNav").length && (app.views.topNav = new h({el: i(".TopNav")})), 
jQuery(".Footer").length && (app.views.footer = new p({el: i(".Footer")})), 
app.settings.isDesktop && setTimeout((function() {jQuery("*").off("touchstart touchmove touchend")}), 100)
new topLogo({ el: jQuery('.siteHeader-wrapper') });


const storageKey = 'theme-preference'

    const onClick = () => {
    // flip current value
    theme.value = theme.value === 'light'
        ? 'dark'
        : 'light'

    setPreference()
    }

    const getColorPreference = () => {
    if (localStorage.getItem(storageKey))
        return localStorage.getItem(storageKey)
    else
        return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    }

    const setPreference = () => {
    localStorage.setItem(storageKey, theme.value)
    reflectPreference()
    }

    const reflectPreference = () => {
    document.firstElementChild
        .setAttribute('data-theme', theme.value)

    document
        .querySelector('#theme-toggle')
        ?.setAttribute('aria-label', theme.value)
    }

    const theme = {
    value: getColorPreference(),
    }

    // set early so no page flashes / CSS is made aware
    reflectPreference()

    window.onload = () => {
    // set on load so screen readers can see latest value on the button
    reflectPreference()

    // now this script can find and listen for clicks on the control
    document
        .querySelector('#theme-toggle')
        .addEventListener('click', onClick)
    }

    // sync with system changes
    window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', ({matches:isDark}) => {
        theme.value = isDark ? 'dark' : 'light'
        setPreference()
    })
