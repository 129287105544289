import _ from "underscore"
import jQuery from "jquery"
import baseThirteen from  "../../base/baseThirteen";
var newsWork = baseThirteen.extend({
    el: ".Work",
    events: {},
    initialize: function(t) {
        baseThirteen.prototype.initialize.call(this, t), 
        this.$(".Work-header").onCustom("screen-position", (function(t, e) {
            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        })), 
        this.$(".Work-title").onCustom("screen-position", (function(t, e) {
            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        }));
        this.$(".Work-subtitle").onCustom("screen-position", (function(t, e) {
            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        }));
        this.$(".workFilter").onCustom("screen-position", (function(t, e) {
            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        }));
        jQuery(window).on("resize", this.onWorkResize), 
        this.onWorkResize()
    },
    onWorkResize: function() {
        jQuery(window).width() <= 375 ? jQuery(".Work-header").addClass("Work-header-margin") : jQuery(".Work-header").removeClass("Work-header-margin")
    }
})

export default newsWork;