import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import baseHome from "./base/baseHome";
import home from "./home";
import homeView from "./view/home";
import news from "./news";
import newsView from "./view/news";
import about from "./about";
import aboutView from "./view/about";
import services from "./services";
import servicesView from "./view/services";
import page from "./page";
import pageView from "./view/page";


import work from "./work";
import workView from "./view/work";
//  var i = n(6),
//  r = n(1),
//  o = n(5),
//  s = n(71),//view/home/home
//  a = n(73),//home
//  l = n(75),
//  u = n(77),
//  c = n(79),
//  h = n(81),
//  p = n(83);
const routing  = Backbone.Router.extend({
    routes: {
        "": "index",
        
        "news(/)(.html)": "news",
        "news(/:slug)(.html)": "news",
        "about(/)(.html)": "about",
        "services(/)(.html)": "services",
        "work(/)(.html)": "work",
        "(:slug)(.html)": "page",
    },
    index: function() {
        // console.log("index");
        this.activate(home, {
            view: homeView,
            viewData: {}
        })
    },
    work: function() {
        // console.log("work");
        this.activate(work, {
            view: workView,
            viewData: {}
        })
    },
    page:function(){
        // console.log("page");
        this.activate(page, {
            view: pageView,
            viewData: {
            }
        })
    },
    news: function(t) {
        // console.log("news");
        this.activate(news, {
            view: newsView,
            viewData: {
                slug: t
            }
        })
    },
    about: function() {
        // console.log("about");
        this.activate(about, {
            view: aboutView,
            viewData: {
            }
        })
    },
    services: function() {
        // console.log("about");
        this.activate(services, {
            view: servicesView,
            viewData: {
            }
        })
    },
    activate: function(t, e) {
        if (e = e || {}, !app.state.view) 
        return e.server = !0, 
        e.inAnimated = !1, 
        app.state.viewConstructor = t, 
        app.state.view = new t(e), 
        app.state.view.activate(), void(app.state.currentViewClass = t);

        app.state.prevView = app.state.view, 
        this.isSectionLogic(app.state.prevView, t, e) ? this.activateSectionLogic(e) : this.activateStandardLogic(t, e), app.state.currentViewClass = t
    },
    activateStandardLogic: function(t, n) {
        var i = r.defaults({
            server: !1,
            inAnimated: !0
        }, n);
        app.state.viewConstructor = t;
        var o = new t(i),
            s = n.fastNavigate ? 0 : 500;
        return Promise.all([app.state.view.playOut({
            duration: s,
            zoom: n.zoomNavigate,
            view: o
        }), 
        baseHome.loadData()])
        .then((function() {
            return app.els.$content.css({
                minHeight: app.els.$content.height()
            }), app.state.view.deactivate({
                destroy: !0
            })
        }))
        .then((function() {
            app.state.isServer = !1, app.state.view = baseHome, app.state.view.activate(i).then((function() {
                return app.els.$content.css({
                    minHeight: ""
                }), e(window).scrollTop(0), app.state.view.playIn({
                    duration: s,
                    zoom: n.zoomNavigate,
                    view: o
                })
            }))
        }))
    },
    activateSectionLogic: function(t) {
        var e = t.view,
            n = r.omit(t, "view");
        n.server = !1, n.inAnimated = !0, app.state.view.update(e, n), app.state.isServer = !1
    },
    isSectionLogic: function(t, e, n) {
        return app.state.prevView instanceof o && app.state.prevView.constructor === e
    },
    start: function() {
        var t = app.els.$body.hasClass("Page404");
        Backbone.history.start({
            pushState: !0,
            hashChange: !1,
            silent: t,
            // root:"/global-grid-agency/"
            root:"/"
        }), 
        jQuery(window).scrollTop(0), 
        t && this.notFound()
    }
})

export default routing