import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
// var i = n(1),
//                 r = n(3);
//             n(70), 
const WorksList = Backbone.View.extend({
    el: ".WorksList",
    events: {
        "mouseenter .WorksList-listItem-title": "onItemMouseEnter",
        "mousemove .WorksList-listItem-title": "onItemMouseMove",
        "mouseleave .WorksList-listItem-title": "onItemMouseLeave",
        "click .WorksList-listItem-title": "onItemClick",
        
    },
    initialize: function (t) {
        this.options = t || {}, 
        _.bindAll(this, "moveImage"), 
        this.moveImage.__throttled = _.throttle(this.moveImage.bind(this), 30), 
        Backbone.View.prototype.initialize.call(this, t)
    },
    onItemMouseEnter: function (t) {
        if (app.settings.isDesktop) {
            var n = jQuery(t.target).closest(".WorksList-listItem"),
                i = n.find(".WorksList-listItem-title");
            n.find(".WorksList-listItem-imageWrapper");
            this.$(".WorksList-listItem").removeClass("active inactive");
            var r = window.getComputedStyle(i[0], ":after"),
                o = parseInt(r.getPropertyValue("margin-top"), 10),
                s = parseInt(r.getPropertyValue("left"), 10),
                a = parseInt(r.getPropertyValue("width"), 10),
                l = parseInt(r.getPropertyValue("height"), 10);
            this.hoverArea = {
                x: i.offset().left + s,
                y: i.offset().top + o,
                w: a,
                h: l
            }, n.addClass("active").siblings().addClass("inactive")
        }
    },
    onItemMouseMove: function (t) {
        this.moveImage.__throttled(t)
    },
    moveImage: function (t) {
        if (app.settings.isDesktop) {
            var n = jQuery(t.currentTarget).closest(".WorksList-listItem").find(".WorksList-listItem-hoverWrapper"),
                i = t.pageX,
                r = t.pageY,
                o = (i - this.hoverArea.x) / this.hoverArea.w,
                s = (r - this.hoverArea.y) / this.hoverArea.h;
            window.innerWidth < 1024 ? n.css("transform", "translate(" + (30 * o - 15) + "px, " + (20 * s - 10) + "px)") : n.css("transform", "translate(" + (60 * o - 30) + "px, " + (40 * s - 20) + "px)")
        }
    },
    onItemMouseLeave: function (t) {
        app.settings.isDesktop && this.$(".WorksList-listItem").removeClass("active inactive")
    },
    onItemClick: function (t) {
        if (!app.settings.isDesktop) {
            var n = jQuery(t.target).closest(".WorksList-listItem"),
                i = n.hasClass("active");
            this.$(".WorksList-listItem").removeClass("active inactive"), i || n.addClass("active").siblings().addClass("inactive")
        }
    }
})
export default WorksList