import _ from "underscore";
import Backbone from "backbone";
import jquery from "jquery";
import Isotope from "isotope-layout"
import projectsItem from "./projectsItem";


const trustedProjectsList = Backbone.View.extend({
    el: ".TrustedProjectsList",
    initialize: function (t) {
        this.filterClicked=false;
        this.options = t || {}, this.$(".TrustedProjectsList-wrapper-inner .ProjectsItem").each((function (t, e) {
            new projectsItem({
                el: this,
                ind: t
            })
        }))
        this.iso = new Isotope( '.TrustedProjectsList-wrapper-inner', {
            itemSelector: '.TrustedProjectsList-wrapper-inner .ProjectsItem',
            percentPosition: true,
            masonry: {
                columnWidth:'.TrustedProjectsList-wrapper-inner .columnWidth',
                gutter:'.TrustedProjectsList-wrapper-inner .gutter'
              }
        });
        // console.log(jquery( '.ProjectsItem:visible')[0])
        jquery( '.TrustedProjectsList-wrapper-inner .ProjectsItem').each(function(){
            jquery(this).attr("index",jquery(this).index())
        })
        Backbone.View.prototype.initialize.call(this, t)
    },

})

export default trustedProjectsList