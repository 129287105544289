import _ from "underscore";
import baseHome from "./base/baseHome";

const page = baseHome.extend({
            el: ".Page",
            events: {},
            initialize: function(t) {
                // console.log("home",this)
                baseHome.prototype.initialize.call(this, t)
            }
        })
export default page;