import _ from "underscore";

import baseThirteen from  "../../base/baseThirteen";

// n(1);
// var i = n(13);
const homeMain = baseThirteen.extend({
    noAnimationOnEqualSubviews: !0,
    initialize: function(t) {
        
        this.isSubSection = !0
        //baseThirteen.prototype.initialize.call(this, t)
    },
    $parent: function() {
        return this.parent.$content
    },
    
})
// var i = n(72);
const home = homeMain.extend({
            initialize: function(t) {
                this.isSubSectionDummy = !0, homeMain.prototype.initialize.call(this, t)
            },
            isRendered: function() {
                return !0
            },
            attach: function() {},
            render: function() {
                return this
            },
            activate: function(t) {
                return this.parent.setTitle(), homeMain.prototype.activate.call(this, t)
            },
            destroy: function() {
                return homeMain.prototype.destroy.call(this)
            },
            deactivate: function(t) {
                return homeMain.prototype.deactivate.call(this, t)
            }
        })

export default home