import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
// var i = n(1),
// r = n(3);
// n(40), 
const ClientsList = Backbone.View.extend({
el: ".ClientsList",
events: {},
SWITCH_PATTERNS: [2, 1, 3, 8, 4, 1, 5, 7, 3, 8, 1, 4, 3, 7, 5, 1, 2, 8, 4, 3, 2, 7, 4, 3, 8, 5, 1, 4, 5, 2, 8, 7, 1, 5, 4, 1, 7, 4, 2, 8, 5, 7, 3, 2, 8, 5, 2, 7, 1, 3, 8, 5, 7, 3, 4, 2],
initialize: function(t) {
    _.bindAll(this, "switchLogoGroup", "resumeAnimation", "pauseAnimation");
    var n, o = this;
    this.LOGOS = {
        1: {
            active: 0,
            logos: ["facebook", "slack"]
        },
        2: {
            active: 0,
            logos: ["google", "coinbase"]
        },
        3: {
            active: 0,
            logos: ["uber", "toyota"]
        },
        4: {
            active: 0,
            logos: ["sony", "coca-cola"]
        },
        5: {
            active: 0,
            logos: ["wd", "t-mobile"]
        },
        6: {
            active: 0,
            logos: ["huawei", "vmware"]
        },
        7: {
            active: 0,
            logos: ["samsung", "fossil"]
        },
        8: {
            active: 0,
            logos: ["adp", "oppo"]
        }
    }, 
    this.$(".ClientsList-title").each(function(t, n) {
        var i = app.utils.showAnimation(this.$(n).find("p"));
        this.$(n).onCustom("screen-position", function(t, e) {
            e.screenPosition > 0 && (e.$el.offCustom("screen-position"), i.play())
        }.bind(this))
    }.bind(this));
    var s = [];
    this.$("#ClientsList-logos-wrapper-desktop .ClientsList-logos-row").onCustom("screen-position", function(t, r) {
        r.screenPosition > 0 && (r.$el.offCustom("screen-position"), s.push(r.$el)), clearTimeout(n), n = setTimeout(function() {
            if (s.length) {
                var t = s[0].offset().top,
                    n = 0,
                    r = 0;
                _.each(s, (function(i, o) {
                    var s = i.offset().top;
                    s > t && (t = s, n += 100, r = 0), this.$(".svg", i).each((function(t, i) {
                        setTimeout((function() {
                            jQuery(i).addClass("show")
                        }), r + n), r += 50
                    }.bind(this)))
                }.bind(this))), s = []
            }
        }.bind(this), 0)
    }.bind(this)), 
    o.$("#ClientsList-logos-wrapper-mobile").onCustom("screen-position", (function(t, e) {
        e.screenPosition > 0 && (e.$el.offCustom("screen-position"), o.$(".svg-mobile").addClass("show"))
    })), 
    this.wasShown = !1, this.currentPattern = -1, 
    this.$("#ClientsList-logos-wrapper-mobile").onCustom("screen-enter", function(t, n) {
        clearTimeout(this.firstTimeAnimationTimer), !this.firstTimeAnimationTimer && jQuery(window).scrollTop() <= 0 ? this.firstTimeAnimationTimer = setTimeout(this.resumeAnimation, 1300) : this.resumeAnimation()
    }.bind(this)), 
    this.$("#ClientsList-logos-wrapper-mobile").onCustom("screen-leave", function(t, e) {
        clearTimeout(this.firstTimeAnimationTimer), this.pauseAnimation()
    }.bind(this)), 
    Backbone.View.prototype.initialize.call(this, t)
},
resumeAnimation: function() {
    var t = this;
    clearTimeout(this.switchLogoTimer);
    var e = 0,
        n = function() {
            e++, t.currentPattern = (t.currentPattern + 1) % t.SWITCH_PATTERNS.length;
            var i = t.SWITCH_PATTERNS[t.currentPattern],
                r = t.switchLogoGroup(i);
            t.switchLogoTimer = setTimeout(n, e % 2 == 0 ? r + 700 : 100)
        };
    this.wasShown ? n() : (_.each(this.LOGOS, function(t, e) {
        setTimeout(function() {
            this.switchLogoGroup(e, !0)
        }.bind(this), 300 * Math.floor((e - 1) / 5))
    }.bind(this)), this.switchLogoTimer = setTimeout(n, 2500)), this.wasShown = !0
},
pauseAnimation: function() {
    clearTimeout(this.switchLogoTimer)
},
switchLogoGroup: function(t, n) {
    if (void 0 === t) {
        do {
            t = Math.floor(5 * Math.random()) + 1
        } while (t === this.prevGroup);
        this.prevGroup = t
    }
    var r = this.LOGOS[t],
        o = this.$(".ClientsList-place--" + t),
        s = function(t, e) {
            var r = t.find("g > g").toArray(),
                o = function(t, i) {
                    i !== t.length && (t[i].addClass(e), setTimeout((function() {
                        o(t, i + 1)
                    }), n ? 60 : 100))
                };
            r = _.shuffle(r), o(r, 0)
        }.bind(this);
    if (r.$prev) {
        var a = r.$prev;
        s(a, "hide"), setTimeout((function() {
            a.remove()
        }), 2e3)
    }
    var l = r.logos[r.active],
        u = jQuery(_.template(this.$("#logo-" + l).text())());
    o.append(u), r.$prev = u, r.active = (r.active + 1) % r.logos.length;
    var c = n ? 17 : 750,
        h = c + u.find("g > g").length * (n ? 60 : 100);
    return setTimeout(function() {
        s(u, n ? "show-first-time" : "show")
    }.bind(this), c), h
}
})
export default ClientsList;