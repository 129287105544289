import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
// var i = n(1),
//                 r = n(3);
//             n(48), 
const indexLogos = Backbone.View.extend({
    el: ".ggaLogos",
    events: {},
    SWITCH_PATTERNS: [1, 7, 8, 2, 4, 12, 3, 8, 10, 9, 11, 5, 8, 4, 13, 5, 1, 9, 10, 2, 7, 12, 6, 8, 7, 11, 9, 7, 3, 10, 7, 4, 2, 13, 7, 3, 2, 8, 9, 12, 4, 10, 5, 6, 7, 1, 3, 13, 9, 8, 10, 7, 5, 11, 1, 3],
      initialize: function(t) {
        this.options = t || {}, _.bindAll(this, "switchLogoGroup", "resumeAnimation", "pauseAnimation"), 
        this.LOGOS = {
            1: {
                active: 0,
                logos: ["miggy", "primier", "ranchoylena" ]
            },
            2: {
                active: 0,
                logos: ["tusimple", "healthy-best", "pca"]
            },
            3: {
                active: 0,
                logos: ["prime", "infused","bazaar"]
            },
            4: {
                active: 0,
                logos: ["tainr", "lala","health-channel"]
            },
            5: {
                active: 0,
                logos: ["smarter-logistic", "theknow"]
            },
            6: {
                active: 0,
                logos: ["nueve", "bsd-capital", "tinybot"]
            },
            7: {
                active: 0,
                logos: ["devia", "infinito", "overair"]
            },
            8: {
                active: 0,
                logos: ["qts", "wellbing", "ecl"]
            },
            9: {
                active: 0,
                logos: ["shelby", "shape-form"]
            },
            10: {
                active: 0,
                logos: ["koosh", "hydron"]
            },
            11: {
                active: 0,
                logos: ["tainr", "lala", "health-channel"]//4
            },
            12: {
                active: 0,
                logos: ["shelby", "shape-form", "hydron"]//9
            },
            13: {
                active: 0,
                logos: ["smarter-logistic", "theknow", "koosh"]//5
            }
            // 1: {
            //     active: 0,
            //     logos: ["facebook", "huawei"]
            // },
            // 2: {
            //     active: 0,
            //     logos: ["google", "fossil"]
            // },
            // 3: {
            //     active: 0,
            //     logos: ["slack", "wd"]
            // },
            // 4: {
            //     active: 0,
            //     logos: ["coinbase", "toyota"]
            // },
            // 5: {
            //     active: 0,
            //     logos: ["coca-cola", "oppo"]
            // },
            // 6: {
            //     active: 0,
            //     logos: ["amazon", "sony"]
            // },
            // 7: {
            //     active: 0,
            //     logos: ["stripe", "vmware"]
            // },
            // 8: {
            //     active: 0,
            //     logos: ["cisco", "t-mobile"]
            // },
            // 9: {
            //     active: 0,
            //     logos: ["uber", "sandisk"]
            // },
            // 10: {
            //     active: 0,
            //     logos: ["ups", "google"]
            // },
            // 11: {
            //     active: 0,
            //     logos: ["coinbase", "toyota"]
            // },
            // 12: {
            //     active: 0,
            //     logos: ["uber", "sandisk"]
            // },
            // 13: {
            //     active: 0,
            //     logos: ["coca-cola", "oppo"]
            // }
            
        }, 
        this.wasShown = !1, 
        this.currentPattern = -1, 
        this.initialLogoCreate(), 
        this.$(".ggaLogos-container").onCustom("screen-enter", this.resumeAnimation), 
        this.$(".ggaLogos-container").onCustom("screen-leave", this.pauseAnimation), 
        // this.$(".IndexLogos-show-more-wrapper").each((function(t, n) {
        //     var i = app.utils.showAnimation(e(n).find("span"));
        //     e(n).onCustom("screen-position", (function(t, e) {
        //         e.screenPosition > 0 && e.$el.offCustom("screen-position"), i.play()
        //     }))
        // })), 
        Backbone.View.prototype.initialize.call(this, t)
    },
    initialLogoCreate: function() {
        _.each(this.LOGOS, function(t, n) {
            t = this.LOGOS[n];
            var r = this.$(".ggaLogos_place_" + n),
                o = t.logos[t.active],
                s = jQuery(_.template(this.$("#logo-" + o).text())());
            r.append(s), t.$prev = s
        }.bind(this))
    },
    resumeAnimation: function() {
        var t = this;
        clearTimeout(this.switchLogoTimer);
        var e = 0,
            n = function() {
                e++, t.currentPattern = (t.currentPattern + 1) % t.SWITCH_PATTERNS.length;
                var i = t.SWITCH_PATTERNS[t.currentPattern],
                    r = t.switchLogoGroup(i);
                t.switchLogoTimer = setTimeout(n, e % 2 == 0 ? r + 700 : 100)
            };
        this.wasShown ? n() : (_.each(this.LOGOS, function(t, e) {
            setTimeout(function() {
                this.switchLogoGroup(e, !0)
            }.bind(this), 300 * Math.floor((e - 1) / 5))
        }.bind(this)), this.switchLogoTimer = setTimeout(n, 2500)), this.wasShown = !0
    },
    pauseAnimation: function() {
        clearTimeout(this.switchLogoTimer)
    },
    switchLogoGroup: function(t, n) {
        if (void 0 === t) {
            do {
                t = Math.floor(5 * Math.random()) + 1
            } while (t === this.prevGroup);
            this.prevGroup = t
        }
        var r = this.LOGOS[t],
            o = this.$(".ggaLogos_place_" + t),
            s = function(t, e) {
                var r = t.find("g > path, g > rect, g > circle").toArray(),
                    o = function(t, i) {
                        i !== t.length && (t[i].addClass(e), setTimeout((function() {
                            o(t, i + 1)
                        }), n ? 60 : 100))
                    };
                r = _.shuffle(r), o(r, 0)
            }.bind(this);
            
        if (r.$prev && !n) {
            var a = r.$prev;
            s(a, "hide"), setTimeout((function() {
                a.remove()
            }), 2e3)
        }
        if (!n) {
            var l = r.logos[r.active],
                u = jQuery(_.template(this.$("#logo-" + l).text())());
            o.append(u), r.$prev = u
        }
        r.active = (r.active + 1) % r.logos.length;
        var c = n ? 17 : 750,
            h = c + r.$prev.find("g > path, g > rect, g > circle").length * (n ? 60 : 100);
        return setTimeout(function() {
            s(r.$prev, n ? "show-first-time" : "show")
        }.bind(this), c), h
    }
})
export default indexLogos