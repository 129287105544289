import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
// n(1);
//             var i = n(3);
//             n(44), 
const indexDescription = Backbone.View.extend({
                el: ".gga_description",
                initialize: function(t) {
                    this.options = t || {};
                    var n = 0;
                    app.vent.on("page-loaded", function() {
                        n = +new Date
                    }.bind(this)), 
                    this.$(".gga_description-text").each(function(t, i) {
                        var r = app.utils.showAnimation(jQuery(i).find('.gga_description-text-line[data-index="1"]')),
                            o = app.utils.showAnimation(jQuery(i).find('.gga_description-text-line[data-index="2"]'));
                            this.$(i).onCustom("screen-position", function(t, e) {
                                if (e.screenPosition > 0) {
                                    e.$el.offCustom("screen-position");
                                    var i = 0; 
                                    + new Date < n + app.settings.animationDelay && (i = (283 + 4 * app.settings.animationDelay) / 1e3), 
                                    r.delay(0 + i), 
                                    o.delay(app.settings.animationDelay / 1e3 + i), 
                                    r.play(), 
                                    o.play()
                                }
                            }.bind(this))
                    }.bind(this)), 
                    this.$(".gga_description-items").each(function(t, i) {
                        var r = app.utils.showAnimation(jQuery(i).find(".gga_description-item-title"));
                        this.$(i).onCustom("screen-position", function(e, i) {
                            if (i.screenPosition > 0) {
                                i.$el.offCustom("screen-position");
                                var o = 0;
                                + new Date < n + app.settings.animationDelay && (o = (283 + 4 * app.settings.animationDelay) / 1e3), 
                                r.delay(t * app.settings.animationDelay / 1e3 + o), 
                                r.play()
                            }
                        }.bind(this))
                    }.bind(this)), 
                    this.$(".gga_description-item").each((function(t, n) {
                        var i, r = "IndexDescription-item-text-line",
                            o = jQuery(n).find(".gga_description-item-title"),
                            s = jQuery(n).find(".gga_description-item-wrapper"),
                            a = !0;
                        o.on("click", (function(t) {
                            a && (s.show(), i = app.utils.splitMultilineToSeparateSpans({
                                $container: jQuery(n).find(".gga_description-item-text p"),
                                className: r,
                                transparent: !0
                            }), a = !1)
                            jQuery(".gga_description-item-title").not(o).removeClass("active")
                            // app.utils.accordeonAnimation("hide", i, jQuery(".gga_description-item-wrapper").not(s), r),
                            o.hasClass("active") ? (o.removeClass("active"), 
                            app.utils.accordeonAnimation("hide", i, s, r)) : (o.addClass("active"), 
                            app.utils.accordeonAnimation("show", i, s, r))
                        }))
                    })), 
                    Backbone.View.prototype.initialize.call(this, t)
                }
            })

export default indexDescription;