import _ from "underscore";
import Backbone from "backbone";
// n(1);
// var i = n(3);
// n(29);
// n(7).TimelineLite, n(8).Expo, n(9).CSSPlugin;
const AboutCapabilities =   Backbone.View.extend({
    el: ".AboutCapabilities",
    events: {},
    initialize: function(t) {
        this.$(".AboutCapabilities-title").each(function(t, n) {
            var i = app.utils.showAnimation(this.$(n).find("p"));
            this.$(n).onCustom("screen-position", function(t, e) {
                e.screenPosition > 0 && (e.$el.offCustom("screen-position"), i.play())
            }.bind(this))
        }.bind(this)), this.$(".AboutCapabilities-category").each(function(t, n) {
            var i = app.utils.showAnimation(this.$(n).find(".AboutCapabilities-title-text p, .AboutCapabilities-category-item p"));
            this.$(n).onCustom("screen-position", function(t, e) {
                e.screenPosition > 0 && (e.$el.offCustom("screen-position"), i.play())
            }.bind(this))
        }.bind(this)), Backbone.View.prototype.initialize.call(this, t)
    }
})
export default AboutCapabilities