import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";

// var i = n(1),
// r = n(3);
// n(42),//nothing 

const indexAbout = Backbone.View.extend({
    el: ".IndexAbout",
    events: {},
    initialize: function (t) {
        this.options = t || {}, _.bindAll(this, "onPageLoaded", "onResize", "setPanelSizeAndPos"),
            this.setPanelSizeAndPos.__debounced = _.debounce(this.setPanelSizeAndPos, 100),
            jQuery(window).on("resize", this.onResize),
            this.onResize(),
            app.vent.on("page-loaded", this.onPageLoaded),
            this.$(".IndexAbout-numbers").each((function (t, n) {
                var i = app.utils.showAnimation(jQuery(n));
                this.$(n).onCustom("screen-position", (function (t, e) {
                    if (e.screenPosition > 0) {
                        e.$el.offCustom("screen-position"), i.play();
                        var n = e.$el.find(".IndexAbout-number span");
                        if (!n.length) return;
                        app.utils.animateNumber(n, n.attr("data-start"), n.attr("data-end"), 0, 1e3, null, "easeOutQuart", ",")
                    }
                }))
            }).bind(this)),
            this.$(".IndexAbout-text").each(function (t, n) {
                var i = app.utils.splitMultilineToSeparateSpans({
                    $container: jQuery(n),
                    className: "IndexAbout-text-line",
                    transparent: !0
                })
                // r = app.utils.showAnimation(this.$(".IndexAbout-more-wrapper"));
                // this.$(n).onCustom("screen-position", (function (t, e) {
                //     if (e.screenPosition > 0) {
                //         e.$el.offCustom("screen-position");
                //         var n = app.utils.animateTextByLines(i, app.settings.animationDuration, app.settings.animationDelay, null, !1, 30, 0);
                //         r.delay(n * app.settings.animationDelay / 1e3), r.play()
                //     }
                // }))
            }.bind(this)),
            Backbone.View.prototype.initialize.call(this, t)
    },
    onPageLoaded: function () {
        this.onResize()
    },
    onResize: function () {
        this.setPanelSizeAndPos(), this.setPanelSizeAndPos.__debounced()
    },
    setPanelSizeAndPos: function () {
        for (var t = document.body.clientWidth, e = [{
            ww: 0,
            margin: -249
        }, {
            ww: 498,
            margin: 0
        }, {
            ww: 542,
            margin: 22
        }, {
            ww: 667,
            margin: 22
        }, {
            ww: 671,
            margin: 24
        }, {
            ww: 768,
            margin: 24
        }, {
            ww: 786,
            margin: 33
        }, {
            ww: 1024,
            margin: 33
        }, {
            ww: 1038,
            margin: 40
        }, {
            ww: 1152,
            margin: 40
        }, {
            ww: 1162,
            margin: 45
        }, {
            ww: 1280,
            margin: 45
        }, {
            ww: 1440,
            margin: 45
        }, {
            ww: 1e4,
            margin: 4325
        }], n = e.length - 1; n >= 0 && !(e[n].ww <= t); n--);
        var i = e[n],
            r = e[n + 1],
            o = (t - i.ww) / (r.ww - i.ww),
            s = (r.margin - i.margin) * o + i.margin,
            a = t - 2 * s,
            l = 0,
            u = window.innerWidth,
            c = app.utils.getScrollbarWidth();
        u >= 768 && u < 1024 && (l = Math.round(337 * (this.$(".IndexAbout-padder").width() - 720 + c) / 720 / 2)),
            u >= 1024 && u < 1152 && (l = Math.round(453 * (this.$(".IndexAbout-padder").width() - 958 + c) / 958 / 2)),
            u >= 1152 && u < 1280 && (l = Math.round(497 * (this.$(".IndexAbout-padder").width() - 1072 + c) / 1072 / 2)),
            u >= 1280 && (l = Math.round(536 * (this.$(".IndexAbout-padder").width() - 1190 + c) / 1190 / 2)),
            // this.$(".IndexAbout-padder").css({
            //    // padding: l + "px 0"
            // }), 
            this.$(".IndexAbout-wrapper").css({
                width: Math.round(a),
                "margin-left": Math.round(s)
            }), app.vent.trigger("about-margin", Math.round(s))
    }
})

export default indexAbout;