import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";

// var i = n(1),
//                 r = n(3);

            
const projectsItem  = Backbone.View.extend({
                events: {
                    mouseenter: "onMouseEnter",
                    mouseleave: "onMouseLeave",
                    "mouseenter .ProjectsItem-imageWrapper": "onImageMouseEnter",
                    "mouseleave .ProjectsItem-imageWrapper": "onImageMouseLeave",
                    "mousemove .ProjectsItem-imageWrapper": "onImageMouseMove",
                    click: "onClick",
                    touchstart: "onTouchStart"
                },
                initialize: function(t) {
                    if (this.options = t || {}, _.bindAll(this, "onPageLoaded", "resumeLoop", "pauseLoop", "loadVideo", "createVideo", "proceedVideoFrame", "onVideoProgressShadow", "onVideoProgressTexts", "playVideo", "onUpdateShadow", "onSomeCardTapped", "onThemeChange", "onBodyTouchstart"), 
                    app.vent.on("page-loaded", this.onPageLoaded), 
                    this.$coming = this.$(".ProjectsItem-coming"), this.$coming.length) {
                        var n = this.$coming.attr("data-timeline");
                        this.comingTimeline = n && JSON.parse(n), this.$coming.attr("data-color", this.comingTimeline[0].color)
                    }
                    this.$texts = this.$(".ProjectsItem-textsItem"), this.$texts.length && (this.textsTimeline = [], this.$texts.each(function(t, e) {
                        this.textsTimeline.push({
                            start: e.getAttribute("data-start"),
                            end: e.getAttribute("data-end"),
                            color: e.getAttribute("data-color")
                        })
                    }.bind(this))), 
                    this.theme = "light", 
                    app.vent.on("card-tapped", this.onSomeCardTapped), 
                    app.vent.on("update-shadow", this.onUpdateShadow), 
                    app.vent.on("theme-change", this.onThemeChange);
                    var o = this.$el.attr("data-shape"),
                        s = this.$el.attr("data-order");
                    if (this.shift = 0, app.settings.isDesktop) {
                        var a = "0" === s ? "rect" === o ? 22 : 30 : "rect" === o ? 11 : 15,
                            l = this.$(".ProjectsItem-innerWrapper"),
                            u = l[0],
                            c = this.$(".ProjectsItem-paralaxWrapper")[0];
                        l.css("top", Math.round(a / 100 * l.height()))
                    }
                    this.$el.onCustom("screen-leave", function(t, e) {
                        t.target === t.currentTarget && this.onSomeCardTapped()
                    }.bind(this)), 
                    this.$el.onCustom("screen-position", function(t, e) {
                        if (t.target === t.currentTarget) {
                            var n = app.utils.easingFunctions(0, 1, e.screenPosition, "easeOutQuad");
                            if (app.settings.isDesktop) {
                                var i = "0" === s ? "rect" === o ? 22 : 30 : "rect" === o ? 11 : 15,
                                    r = Math.round(e.height * n * i / 100);
                                e.height != this.prevDataHeight && (u.style.top = Math.round(i / 100 * e.height) + "px", this.prevDataHeight = e.height), this.shift != r && (c.style.transform = "translate3d(0, " + -r + "px, 0)", this.shift = r)
                            } else this.shift = 0;
                            this.shift += .14 * e.height
                        }
                    }.bind(this));
                    var h = this;
                    app.vent.on("second-card-shown", function() {
                        0 === t.ind && 
                        this.$(".ProjectsItem-imageClipper").triggerCustom("screen-position", {
                            screenPosition: 1,
                            $el: this.$(".ProjectsItem-imageClipper")
                        })
                    }.bind(this)), 
                    this.$(".ProjectsItem-imageClipper").onCustom("screen-position", function(n, i) {
                        if (i.screenPosition > 0) {
                            var r = function() {
                                h.$el.addClass("show"), i.$el.addClass("remove-clipping")
                            };
                            jQuery(window).scrollTop() <= 0 ? this.firstTimeAnimationTimer = setTimeout(r, 0) : r(), i.$el.offCustom("screen-position"), app.settings.lazyVideo && !app.settings.isMobile && this.loadVideo(), 1 === t.ind && app.vent.trigger("second-card-shown")
                        }
                    }.bind(this)), 
                    this.$(".ProjectsItem-title").onCustom("screen-bottom", function(t, e) {
                        e.screenBottom + this.shift > 50 && e.$el.addClass("show").offCustom("screen-bottom")
                    }.bind(this)), 
                    this.$(".ProjectsItem-description").onCustom("screen-bottom", function(t, e) {
                        e.screenBottom + this.shift > 50 && e.$el.addClass("show").offCustom("screen-bottom")
                    }.bind(this)), 
                    Backbone.View.prototype.initialize.call(this, t), 
                    this.createVideo(), 
                    app.vent.trigger("update-shadow", {}), 
                    app.settings.lazyVideo || (app.settings.isMobile ? app.settings.canAutoplay ? setTimeout(this.loadVideo, 100 * this.options.ind) : jQuery("body").one("touchstart", this.loadVideo) : setTimeout(this.loadVideo, 100 * this.options.ind))
                },
                onPageLoaded: function() {},
                onImageMouseEnter: function(t) {
                    app.settings.isMobile || (clearTimeout(this.showCursorTimeout), clearTimeout(this.hideCursorTimeout), this.$(".ProjectsItem-cursor").show(), this.showCursorTimeout = setTimeout(function() {
                        this.$(".ProjectsItem-cursor").addClass("show")
                    }.bind(this), 17))
                },
                onImageMouseLeave: function(t) {
                    app.settings.isMobile || (clearTimeout(this.showCursorTimeout), clearTimeout(this.hideCursorTimeout), this.$(".ProjectsItem-cursor").removeClass("show"), this.hideCursorTimeout = setTimeout(function() {
                        this.$(".ProjectsItem-cursor").hide()
                    }.bind(this), 670))
                },
                onImageMouseMove: function(t) {
                    if (!app.settings.isMobile) {
                        var e = t.pageX - this.$(".ProjectsItem-imageWrapper").offset().left,
                            n = t.pageY - this.$(".ProjectsItem-imageWrapper").offset().top;
                        this.$(".ProjectsItem-cursor").css({
                            transform: "translate3d(" + e + "px, " + n + "px, 0)"
                        })
                    }
                },
                loadVideo: function() {
                    // this.loadInitiated || (jQuery(this.video).attr("src", jQuery(this.video).attr("data-src")), this.video.load(), this.loadInitiated = !0)
                },
                onSomeCardTapped: function(t) {
                    this !== t && app.settings.isMobile && this.$el.hasClass("tapped") && (this.pauseLoop(), this.$el.removeClass("tapped"))
                },
                onThemeChange: function(t) {
                    this.overlayColors = {
                        r: "light" === t ? 255 : 0,
                        g: "light" === t ? 255 : 0,
                        b: "light" === t ? 255 : 0
                    }, this.theme = t, this.onUpdateShadow()
                },
                onClick: function(t) {
                    if (app.settings.isMobile) {
                        if (app.settings.lazyVideo && this.loadVideo(), clearTimeout(this.supressResetTimeout), this.supressClick) return this.supressClick = !1, void(window.innerWidth <= window.app.settings.phonePortrait && this.$el.flashClass("clicked", 167));
                        this.$el.hasClass("tapped") && (this.$el.attr("data-url"), this.$el.flashClass("clicked", 167))
                    } else this.$el.flashClass("clicked", 167)
                },
                onTouchStart: function(t) {
                    app.settings.isMobile && (this.$el.hasClass("tapped") || (app.vent.trigger("card-tapped", this), this.resumeLoop(), this.$el.attr("data-url") && (t.preventDefault(), t.stopPropagation()), this.$el.addClass("tapped"), clearTimeout(this.supressResetTimeout), this.supressClick = !0, this.supressResetTimeout = setTimeout(function() {
                        this.supressClick = !1
                    }.bind(this), 750), jQuery("body").off("touchstart", this.onBodyTouchstart), jQuery("body").on("touchstart", this.onBodyTouchstart)))
                },
                onBodyTouchstart: function(t) {
                    jQuery(t.target).closest(".ProjectsItem").length || (jQuery("body").off("click", this.onBodyTouchstart), app.vent.trigger("card-tapped", null))
                },
                isWeakDevice: function() {
                    if (app.settings.isDesktop) return !1;
                    if (app.settings.iOSVersion < 11) return !0;
                    if (app.settings.isPhone) return !1;
                    return !!{
                        "iPad 2 or Mini": !0,
                        "iPad 3": !0,
                        "iPad 4": !0,
                        "iPad Air 2": !0,
                        "iPad Air, Mini 2, Mini 3": !0,
                        iPad: !0,
                        "iPad, iPad 2, iPad Mini": !0,
                        "Not an iPad": !0
                    }[app.settings.ipadModel]
                },
                createVideo: function() {
                    if (!this.video) {
                        var t = this.isWeakDevice() ? this.$el.attr("data-video-min") : this.$el.attr("data-video"),
                            n = jQuery('<video class="lazyload" muted playsinline crossorigin loop preload="auto" poster="' + this.$(".ProjectsItem-imageClipper").attr("data-video-poster") + '" src="' + app.utils.stubVideo + '" data-src="' + t + '"></video>');
                        this.$(".ProjectsItem-videoWrapper").prepend(n), n.addClass("ProjectsItem-video"), 
                        this.video = n.get(0);
                        var i = jQuery("<canvas>");
                        this.$(".ProjectsItem-shadowWrapper").prepend(i), this.video && this.video.addEventListener("ended", function() {
                            this.video.play()
                        }.bind(this), !1), this.canvas = i[0], this.ctx = this.canvas.getContext("2d"), this.overlayColors = {
                            r: "dark" === this.theme ? 0 : 255,
                            g: "dark" === this.theme ? 0 : 255,
                            b: "dark" === this.theme ? 0 : 255
                        }
                    }
                },
                onMouseEnter: function(t) {
                    app.settings.isMobile || this.resumeLoop()
                },
                onMouseLeave: function(t) {
                    app.settings.isMobile || this.pauseLoop()
                },
                onVideoProgressTexts: function() {
                    var t = 1e3 * this.video.currentTime,
                        e = this.comingTimeline,
                        n = this.textsTimeline;
                    if (e)
                        for (var i = 0; i < e.length; i++)
                            if (e[i].start <= t && t <= e[i].end) {
                                this.$coming.attr("data-color", e[i].color);
                                break
                            }
                    if (n)
                        for (i = 0; i < n.length; i++) n[i].start <= t && t <= n[i].end ? this.$texts.eq(i).addClass("show") : this.$texts.eq(i).removeClass("show")
                },
                onVideoProgressShadow: function() {
                    if (this.canvas && (3 === this.video.readyState || 4 === this.video.readyState) && !app.settings.isIE) {
                        new Date;
                        var t = +new Date - this.progressStartTime - 400;
                        t > 0 && this.drawShadow(t)
                    }
                },
                onUpdateShadow: function(t) {
                    this.shadowParams = t, "#controls" === window.location.hash && "w-ptheme.club"  !== window.location.hostname.toLowerCase() || ("light" === this.theme && (this.shadowParams = {
                        crop: 0,
                        resolution: .3,
                        opacity: .3,
                        blur: 22,
                        spread: 1,
                        shift: 2,
                        overlayOpacity: .7,
                        overallOpacity: .4
                    }), "dark" === this.theme && (this.shadowParams = {
                        crop: 0,
                        resolution: .3,
                        opacity: 1,
                        blur: 22,
                        spread: 1,
                        shift: 3,
                        overlayOpacity: .52,
                        overallOpacity: .4
                    })), this.updateShadow()
                },
                updateShadow: function() {
                    this.canvas && jQuery(this.canvas).css({
                        filter: "blur(" + this.shadowParams.blur + "px)",
                        "margin-top": this.shadowParams.shift + "%"
                    }), this.shadowParams.cw = jQuery(this.canvas).width() * this.shadowParams.resolution, this.shadowParams.ch = jQuery(this.canvas).height() * this.shadowParams.resolution, this.canvas && (this.canvas.width = this.shadowParams.cw, this.canvas.height = this.shadowParams.ch)
                },
                drawShadow: function(t) {
                    var e = this.shadowParams;
                    this.ctx.drawImage(this.video, -e.cw * e.crop, -e.ch * e.crop, e.cw * (1 + 2 * e.crop), e.ch * (1 + 2 * e.crop));
                    var n, i, r = -4,
                        o = 0,
                        s = 0,
                        a = 0,
                        l = 0,
                        u = (n = this.ctx.getImageData(0, 0, e.cw, e.ch)).data;
                    for (i = u.length;
                        (r += 20) < i;) ++o, s += u[r], a += u[r + 1], l += u[r + 2];
                    var c, h, p = (.2126 * s + .7152 * a + .0722 * l) / o;
                    s = ~~(s / o), a = ~~(a / o), l = ~~(l / o), this.overlayColors.r = this.overlayColors.r - .45 * (this.overlayColors.r - s), this.overlayColors.g = this.overlayColors.g - .45 * (this.overlayColors.g - a), this.overlayColors.b = this.overlayColors.b - .45 * (this.overlayColors.b - l), c = "light" === this.theme ? Math.min(Math.max(Math.pow(p / 255, .5), .4), 1) * e.opacity + (1 - e.opacity) : Math.min(Math.max(Math.pow(1 - p / 255, .5), .4), 1) * e.opacity + (1 - e.opacity);
                    var f;
                    f = "light" === this.theme ? (Math.min(Math.max(p / 255, .8), 1) - .8) / (1 - .8) : (Math.min(Math.max((1 - p) / 255, .8), 1) - .8) / (1 - .8), h = e.overlayOpacity + f * (1 - e.overlayOpacity);
                    var d = e.overallOpacity * (Math.min(t, 1e3) / 1e3);
                    c *= d, h *= d, r = -4;
                    for (var m = Math.round(255 * c);
                        (r += 4) < i;) u[r + 3] = m;
                    this.ctx.putImageData(n, 0, 0), this.ctx.globalAlpha = h, this.ctx.fillStyle = "rgb(" + Math.round(this.overlayColors.r) + ", " + Math.round(this.overlayColors.g) + ", " + Math.round(this.overlayColors.b) + ")", this.ctx.fillRect(0, 0, e.cw, e.ch), this.ctx.globalAlpha = 1, "dark" === this.theme && (this.ctx.globalAlpha = .3, this.ctx.globalCompositeOperation = "luminosity", this.ctx.fillStyle = "rgb(0, 0, 0)", this.ctx.fillRect(0, 0, e.cw, e.ch), this.ctx.globalCompositeOperation = "source-over", this.ctx.globalAlpha = 1)
                },
                positionTexts: function() {
                    this.$texts.filter('[data-positionv="center"]').each(function(t, n) {
                        var i = jQuery(n).height(),
                            r = parseInt(jQuery(n).css("font-size"), 10);
                            jQuery(n).css("margin-top", -Math.round(i / 2 - 16 * r / 24))
                    }.bind(this))
                },
                proceedVideoFrame: function() {
                    var t = app.settings.isMobile && this.isWeakDevice() ? 3 : 2;
                    this.frameCounter % t && this.onVideoProgressShadow(), this.onVideoProgressTexts(), this.frameCounter++, this.videoFrameID = window.requestAnimationFrame(this.proceedVideoFrame)
                },
                resumeLoop: function() {
                    clearTimeout(this.pauseTimeout), clearTimeout(this.shadowHideTimeout), clearTimeout(this.playTimeout), window.cancelAnimationFrame(this.videoFrameID), this.positionTexts(), this.loadVideo(), this.videoReady || 3 === this.video.readyState || 4 === this.video.readyState ? this.playVideo() : (jQuery(this.video).on("canplay", this.playVideo), jQuery(this.video).on("canplaythrough", this.playVideo))
                },
                playVideo: function() {
                    jQuery(this.video).off("canplay", this.onVideoCanPlay), jQuery(this.video).off("canplaythrough", this.onVideoCanPlay), this.videoReady = !0, this.$el.addClass("show-video"), this.playTimeout = setTimeout(function() {
                        this.$el.addClass("show-shadow"), this.progressStartTime = +new Date, this.frameCounter = 0, this.proceedVideoFrame()
                    }.bind(this), 350), this.$(".ProjectsItem-texts").show(), this.shadowVisible || (this.$(".ProjectsItem-shadowWrapper").css("display", "block"), this.shadowVisible = !0), this.updateShadow(), this.drawShadow(1), this.video.play()
                },
                pauseLoop: function() {
                    jQuery(this.video).off("canplay", this.onVideoCanPlay), jQuery(this.video).off("canplaythrough", this.onVideoCanPlay), clearTimeout(this.pauseTimeout), clearTimeout(this.shadowHideTimeout), clearTimeout(this.playTimeout), window.cancelAnimationFrame(this.videoFrameID), this.$texts.hide(), this.$texts.removeClass("show"), this.$texts.outerHeight(), this.$texts.show(), this.$el.removeClass("show-video"), this.$el.removeClass("show-shadow"), this.video && this.video.pause(), this.pauseTimeout = setTimeout(function() {
                        this.video && (this.video.currentTime = 0), this.$(".ProjectsItem-texts").hide()
                    }.bind(this), 350), this.shadowHideTimeout = setTimeout(function() {
                        this.shadowVisible && (this.$(".ProjectsItem-shadowWrapper").css("display", ""), this.shadowVisible = !1)
                    }.bind(this), 1e3)
                }
            })
export default projectsItem;