import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import "jquery-touchswipe"
import "./../../uiux/lettering"

//console.log("test",jQuery.fn.lettering)
// var i = n(1),
// r = n(3);
// n(33), n(34), n(35);
// n(7).TimelineLite, n(8).Expo, n(9).CSSPlugin;
const AboutQuotes = Backbone.View.extend({
    el: ".AboutQuotes",
    events: {
        "mouseenter .AboutQuotes-nav-arrows-wrapper": "onArrowsWrapperMouseEnter",
        "mouseleave .AboutQuotes-nav-arrows-wrapper": "onArrowsWrapperMouseLeave",
        "mouseenter .AboutQuotes-nav-arrow-hover-area-right": "onArrowHoverAreaRightMouseEnter",
        "mouseenter .AboutQuotes-nav-arrow-hover-area-left": "onArrowHoverAreaLeftMouseEnter",
        "mouseleave .AboutQuotes-nav-arrow-hover-area-right": "onArrowHoverAreaRightMouseLeave",
        "mouseleave .AboutQuotes-nav-arrow-hover-area-left": "onArrowHoverAreaLeftMouseLeave",
        "click .AboutQuotes-nav-arrow-hover-area-left": "goPrev",
        "click .AboutQuotes-nav-arrow-hover-area-right": "goNext"
    },
    initialize: function (t) {
        _.bindAll(this, "onArrowsWrapperMouseEnter", "onArrowsWrapperMouseLeave", "onResize"), 
        this.countOfQuotes = 5, 
        this.activeQuote = 1, 
        this.ww = jQuery(window).width(), 
        this.partsCount = 7, 
        this.quoteAnimating = !0, 
        jQuery(window).on("resize", this.onResize);
        var n = this,
            r = this.$("#quote-" + this.activeQuote);
        r.css({
            display: "block"
        }), 
        this.containerOffsetLeft = this.$("#quote-" + this.activeQuote).offset().left, 
        this.containerWidth = this.$("#quote-" + this.activeQuote).width(), 
        this.$(".AboutQuotes-nav-arrows-wrapper").each(function (t, n) {
            var i = app.utils.showAnimation(jQuery(n).find(".AboutQuotes-nav-arrow-left")),
                r = app.utils.showAnimation(jQuery(n).find(".AboutQuotes-nav-arrow-right"));
            this.$(n).onCustom("screen-position", function (t, e) {
                e.screenPosition > 0 && (e.$el.offCustom("screen-position"), i.play(), r.play())
            }.bind(this))
        }.bind(this)), 
        r.each(function (t, n) {
            var i = r.find(".AboutQuotes-quote-text span"),
                o = app.utils.splitMultilineToSeparateSpans({
                    $container: i,
                    className: "AboutQuotes-quote-line",
                    transparent: !0
                });
            this.$(n).onCustom("screen-position", function (t, e) {
                e.screenPosition > 0 && (e.$el.offCustom("screen-position"), app.utils.animateTextByLines(o, app.settings.animationDuration, app.settings.animationDelay, function () {
                    o.cancel(!1), this.quoteAnimating = !1
                }.bind(this), !1, 30, 0))
            }.bind(this))
        }.bind(this)), 
        r.find(".AboutQuotes-quote-author").each(function (t, n) {
            var i = app.utils.showAnimation(r.find(".AboutQuotes-quote-author p"));
            this.$(n).onCustom("screen-position", function (t, e) {
                e.screenPosition > 0 && (e.$el.offCustom("screen-position"), e.$el.css({
                    transition: "none"
                }).addClass("show"), i.play(), setTimeout((function () {
                    e.$el.css({
                        transition: ""
                    })
                }), 100))
            }.bind(this))
        }.bind(this)), 
        this.$("#quote-" + this.activeQuote + " span.row").addClass("show"), 
        jQuery(".AboutQuotes-quotes-wrapper").swipe({
            swipeLeft: function (t, e) {
                n.goNext()
            },
            swipeRight: function (t, e) {
                n.goPrev()
            }
        }), 
        jQuery(".AboutQuotes-nav-arrows-wrapper").swipe({
            swipeLeft: function (t, e) {
                n.goNext()
            },
            swipeRight: function (t, e) {
                n.goPrev()
            }
        })
    },
    prepareQuote: function (t) {
        var e = this.$("#quote-" + t).css("display", "block").find(".AboutQuotes-quote-text span.row");
        e.hasClass("splitted") || e.addClass("splitted").lettering()
    },
    goNext: function () {
        if (!this.quoteAnimating) {
            this.quoteAnimating = !0, setTimeout(function () {
                this.quoteAnimating = !1
            }.bind(this), 1e3), this.prepareQuote(this.activeQuote), this.removeQuote(!0);
            var t = this,
                e = this.getNextQuoteNumber();
            this.$("#quote-" + this.activeQuote + " .AboutQuotes-quote-author").removeClass("show"), this.prepareQuote(e), setTimeout((function () {
                t.$("#quote-" + e + " .AboutQuotes-quote-author").addClass("show"), t.showQuote(e, !0)
            }), 10), this.removeAllClasses(), this.setNextActiveQuote(1), this.setNewClasses(1)
        }
    },
    goPrev: function () {
        if (!this.quoteAnimating) {
            this.quoteAnimating = !0, setTimeout(function () {
                this.quoteAnimating = !1
            }.bind(this), 1e3), this.prepareQuote(this.activeQuote), this.removeQuote(!1);
            var t = this,
                e = this.getPrevQuoteNumber();
            this.$("#quote-" + this.activeQuote + " .AboutQuotes-quote-author").removeClass("show"), this.prepareQuote(e), setTimeout((function () {
                t.$("#quote-" + e + " .AboutQuotes-quote-author").addClass("show"), t.showQuote(e, !1)
            }), 10), this.removeAllClasses(), this.setNextActiveQuote(-1), this.setNewClasses(-1)
        }
    },
    removeQuote: function (t) {
        var e = this.activeQuote,
            n = (this.$("#quote-" + e + " span.row"), this.$("#quote-" + e + " span.row > span")),
            i = t ? this.partsCount : 0;
        this.animateLetters(n, i, e, t, !0)
    },
    showQuote: function (t, e) {
        this.$("#quote-" + t + " span.row");
        var n = this.$("#quote-" + t + " span.row > span"),
            i = e ? this.partsCount : 0;
        this.animateLetters(n, i, t, e, !1)
    },
    animateLetters: function (t, e, n, i, r) {
        for (var o = 0, s = 0; s <= this.partsCount; s++) {
            for (var a = i ? this.partsCount - e : e, l = this.containerOffsetLeft + e * this.containerWidth / this.partsCount + (0 === e ? -15 : 0), u = this.containerOffsetLeft + (e + 1) * this.containerWidth / this.partsCount, c = 0; c < t.length; c++) {
                var h = t.eq(c),
                    p = h.offset().left;
                if (p >= l && p < u) {
                    var f = 50 * a + Math.round(170 * Math.random()) + (r ? 0 : 40);
                    h.css("transition-delay", f + "ms"), o = Math.max(o, f)
                }
            }
            i ? e-- : e++
        }
        var d = this.$("#quote-" + n + " span.row");
        d.toggleClass("show", !r).addClass("accelerated"), clearTimeout(d.attr("data-timer"));
        var m = setTimeout((function () {
            d.removeClass("accelerated")
        }), o + 400);
        d.attr("data-timer", m)
    },
    onArrowsWrapperMouseEnter: function () {
        this.$(".AboutQuotes-nav-arrow-left").addClass("area-hover"), this.$(".AboutQuotes-nav-arrow-right").addClass("area-hover")
    },
    onArrowsWrapperMouseLeave: function () {
        this.$(".AboutQuotes-nav-arrow-left").removeClass("area-hover"), this.$(".AboutQuotes-nav-arrow-right").removeClass("area-hover")
    },
    removeAllClasses: function () {
        for (var t = 0; t < this.countOfQuotes; t++) {
            var e = "#line" + (t + 1);
            this.$(e).removeClass("prev-active"), this.$(e).removeClass("prev-active-arrow-hover"), this.$(e).removeClass("next-active"), this.$(e).removeClass("next-active-arrow-hover"), this.$(e).removeClass("active")
        }
    },
    setNewClasses: function (t) {
        var e = "#line" + this.activeQuote;
        this.$(e).addClass("active");
        var n = "#line" + this.getPrevQuoteNumber();
        this.$(n).addClass("prev-active"), -1 !== t || app.settings.isMobile || this.$(n).addClass("prev-active-arrow-hover");
        var i = "#line" + this.getNextQuoteNumber();
        this.$(i).addClass("next-active"), 1 !== t || app.settings.isMobile || this.$(i).addClass("next-active-arrow-hover")
    },
    setNextActiveQuote: function (t) {
        this.activeQuote = -1 === t ? this.getPrevQuoteNumber() : this.getNextQuoteNumber()
    },
    getPrevQuoteNumber: function () {
        return 1 !== this.activeQuote ? this.activeQuote - 1 : this.countOfQuotes
    },
    getNextQuoteNumber: function () {
        return this.activeQuote !== this.countOfQuotes ? this.activeQuote + 1 : 1
    },
    onArrowHoverAreaRightMouseEnter: function () {
        this.$(".next-active").addClass("next-active-arrow-hover")
    },
    onArrowHoverAreaRightMouseLeave: function () {
        this.$(".next-active").removeClass("next-active-arrow-hover")
    },
    onArrowHoverAreaLeftMouseEnter: function () {
        this.$(".prev-active").addClass("prev-active-arrow-hover")
    },
    onArrowHoverAreaLeftMouseLeave: function () {
        this.$(".prev-active").removeClass("prev-active-arrow-hover")
    },
    onResize: function () {
        this.ww = jQuery(window).width(), this.containerOffsetLeft = this.$("#quote-" + this.activeQuote).offset().left, this.containerWidth = this.$("#quote-" + this.activeQuote).width()
    }
})
export default AboutQuotes;