import _ from "underscore";
import baseHome from "./base/baseHome";

const services = baseHome.extend({
            el: ".Services",
            events: {},
            initialize: function(t) {
                // console.log("home",this)
                baseHome.prototype.initialize.call(this, t)
            }
        })
export default services;