import _ from "underscore";
import baseThirteen from "./baseThirteen";
import videoHead from "../view/home/videoHead";
import indexDescription from "../view/home/indexDescription"
import indexLogos from "../view/home/indexLogos"
import projectsList from "../view/home/projectsList"
import trustedProjectsList from "../view/home/trustedProjectsList"
import indexAbout from "../view/home/indexAbout"
import indexNews from "../view/home/indexNews"
import aboutGallery from "../view/home/aboutGallery"
import AboutAwards from "../view/about/awards";
import AboutQuotes from "../view/about/AboutQuotes";
import AboutWhatWeDo from "../view/about/AboutWhatWeDo";
import ClientsList from "../view/about/ClientsList";
import AboutCapabilities from "../view/about/AboutCapabilities";
import WorksList from "../view/work/WorksList";
import ServicesType from "../view/services/ServicesType";

// import indexAbout from "../view/IndexAbout";
// var i = n(1),
// r = n(13);
// n(25);
        // var o = n(26),
        //     s = n(28),
        //     a = n(30),
        //     l = n(32),
        //     u = n(37),
        //     c = n(39),
        //     h = n(41),
        //     p = n(43),
        //     f = n(45),
        //     d = n(47),
        //     m = n(49),
        //     v = n(51),
        //     g = n(55),
        //     y = n(69);
const baseHome = baseThirteen.extend({
            collapseSubviewUpdatePlayInOut: !1,
            noAnimationOnEqualSubviews: !1,
            VIEWS_TO_REGISTER: {
                AboutAwards: {
                    selector: ".AboutAwards",
                    viewConstructor: AboutAwards
                },
                AboutCapabilities: {
                    selector: ".AboutCapabilities",
                    viewConstructor: AboutCapabilities
                },
                AboutGallery: {
                    selector: ".gga_gallery",
                    viewConstructor: aboutGallery
                },
                AboutQuotes: {
                    selector: ".AboutQuotes",
                    viewConstructor: AboutQuotes
                },
                AboutWhatWeDo: {
                    selector: ".AboutWhatWeDo",
                    viewConstructor: AboutWhatWeDo
                },
                ServicesType: {
                    selector: ".Services-type-section",
                    viewConstructor: ServicesType
                },
                // serviceClientsList: {
                //     selector: ".supported_tech_stacks",
                //     viewConstructor: serviceClientsList
                // },
                ClientsList: {
                    selector: ".ClientsList",
                    viewConstructor: ClientsList
                },
                IndexAbout: {
                    selector: ".IndexAbout",
                    viewConstructor: indexAbout
                },
                IndexDescription: {
                    selector: ".gga_description",
                    viewConstructor: indexDescription
                },
               
                IndexLogos: {
                    selector: ".ggaLogos",
                    viewConstructor: indexLogos
                },
                IndexNews: {
                    selector: ".IndexNews",
                    viewConstructor: indexNews
                },
                ProjectsList: {
                    selector: ".ProjectsList",
                    viewConstructor: projectsList
                },
                trustedProjectsList: {
                    selector: ".TrustedProjectsList",
                    viewConstructor: trustedProjectsList
                },
                VideoHead: {
                    selector: ".gga_video_wrapper",
                    viewConstructor: videoHead
                },
                 // IndexFaq: {
                //     selector: ".IndexFaq",
                //     viewConstructor: f
                // },
                WorksList: {
                    selector: ".WorksList",
                    viewConstructor: WorksList
                }
            },
            initialize: function(t) {
                // console.log("baseHome",this.el)
                this.isSection = !0, 
                baseThirteen.prototype.initialize.call(this, t);
                var e = this.options.view;
                // console.table("initialize",this.options)
                this.registerView("view", new e(_.defaults(this.options.viewData, {
                    inAnimated: !1,
                    server: this.options.server
                }))), 
                this.$content = this.$(".Section-content")
            },
            render: function() {
                var t = baseThirteen.prototype.render.call(this);
                return this.$content = this.$(".Section-content"), t
            },
            viewsRegistration: function() {
                baseThirteen.prototype.viewsRegistration.call(this)
            },
            activate: function() {
                return baseThirteen.prototype.activate.call(this)
            },
            update: function(t, e) {
                var n = this.getView("view");
                this.options.viewData = e.viewData;
                var r = new t(_.defaults(this.options.viewData, {
                    inAnimated: !0,
                    server: !1
                }));
                this.registerView("view", r);
                var o = n.constructor === r.constructor && r.noAnimationOnEqualSubviews,
                    s = e.fastNavigate ? 0 : 500;
                o ? this.loadData().then((function() {
                    return r.activate()
                })).then((function() {
                    return n.playOut({
                        duration: 0,
                        view: r
                    })
                })).then((function() {
                    return n.deactivate({
                        destroy: !0
                    })
                })).then((function() {
                    return r.playIn({
                        duration: 0,
                        view: r
                    })
                })) : this.collapseSubviewUpdatePlayInOut ? this.loadData().then((function() {
                    return r.activate()
                })).then((function() {
                    return Promise.all([n.playOut({
                        duration: s,
                        view: r
                    }), r.playIn({
                        duration: s,
                        zoom: e.zoomNavigate,
                        view: r
                    })])
                })).then((function() {
                    return n.deactivate({
                        destroy: !0
                    })
                })) : Promise.all([n.playOut({
                    duration: s,
                    view: r
                }), this.loadData()]).then((function() {
                    return n.deactivate({
                        destroy: !0
                    })
                })).then((function() {
                    return r.activate()
                })).then((function() {
                    return r.playIn({
                        duration: s,
                        view: r
                    })
                }))
            },
            deactivate: function(t) {
                return baseThirteen.prototype.deactivate.call(this, t)
            },
            loadData: function() {
                return Promise.all([baseThirteen.prototype.loadData.call(this), this.getView("view").loadData()])
            }
        })

export default baseHome