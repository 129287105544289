import jQuery from "jquery";
import _ from "underscore";
import baseThree from "./baseThree";
// import newslist from "./../data/news.json"
const baseThirteen = baseThree.extend({
    type: "",
    title: void 0,
    initialize: function (t) {
        this.loadNews();
        // console.log("baseThirteen",this.el)
        baseThree.prototype.initialize.call(this, t),
            this.options.view && (this.onResize = this.onResize.bind(this),
                this.onScroll = this.onScroll.bind(this),
                this.resourcesAndVideoCheckDone = this.resourcesAndVideoCheckDone.bind(this),
                this.rootNode = document.documentElement || document.body.parentNode || document.body,
                this.lastScrollEvent = +new Date,
                this.scrollItems = [],
                jQuery("[data-scroll]").each(function (t, n) {
                    this.scrollItems.push({
                        el: n,
                        $el: jQuery(n),
                        offset: (jQuery(n).attr("data-scroll-offset") - 0) * (app.settings.isPhone ? 1 : 2),
                        screenPosition: -1,
                        listeners: {
                            "screen-position": [],
                            "screen-bottom": [],
                            "screen-enter": [],
                            "screen-leave": []
                        }
                    }),
                        jQuery(n).data("listeners", this.scrollItems[this.scrollItems.length - 1].listeners)
                }.bind(this)),
                this.scrollSections = [], jQuery("[data-scroll-section]").each(function (t, n) {
                    this.scrollSections.push({
                        el: n,
                        $el: jQuery(n),
                        theme: jQuery(n).attr("data-scroll-section")
                    })
                }.bind(this)),
                this.lazyImgs = [],
                jQuery("[data-lazy]").each(function (t, n) {
                    this.lazyImgs.push({
                        el: n,
                        $el: jQuery(n),
                        src: jQuery(n).attr("data-src"),
                        srcset: jQuery(n).attr("data-srcset"),
                        sizes: jQuery(n).attr("data-sizes")
                    })
                }.bind(this)),
                app.vent.on("page-height-changed", this.onResize),
                this.resourcesAndVideoCheckDone()

            )
    },
    loadNews: function () {
        let wrapper = jQuery('.IndexNews')
        let ul = wrapper.find(".IndexNews-wrapper > ul")
        if (ul.hasClass('added')) return;
        import("../data/news").then((Module) => {
            if (ul.hasClass('added')) return;
            Module.default.forEach((news, index) => {
                let newsDate = news.date.split(",")
                // console.log(news)
                let newsEl = jQuery(`<li data-scroll data-scroll-offset="50" >
                                        <div class="IndexNews-date"><span>${newsDate[0]}</span></div>
                                        <div class="IndexNews-link">
                                            <a target="_self" href="${gga.baseurl}news/${news.slug}">
                                                <span class="IndexNews-link-inner">
                                                </span>
                                                <div class="IndexNews-imageContainer">
                                                    <img class="lazyload" data-src="assets/images/news/${news.thumbImage}" alt="" />
                                                </div>
                                            </a>
                                        </div>
                                    </li>`)
                
                news.title.forEach(titleel => {
                    newsEl.find(".IndexNews-link-inner").append(`<div class="IndexNews-link-line">${titleel}</div>`)
                });
                if(index>3){
                    newsEl.addClass('hide')
                }
                ul.append(newsEl)
            });


            wrapper.find(".IndexNews-wrapper > ul").addClass('added')
        })

    },
    setTitle: function (t) {
        (t = t || this.title) && (document.title = t ? jQuery("<div>" + t + "</div>").text() : "")
    },
    $parent: function () {
        return app.els.$content
    },
    viewsRegistration: function () {
        baseThree.prototype.viewsRegistration.call(this)
    },
    activate: function (t) {
        this.setTitle();
        return baseThree.prototype.activate.call(this, t)
    },
    deactivate: function (t) {
        return jQuery(window).off("resize.page"), window.removeEventListener("scroll", this.onScroll), r.prototype.deactivate.call(this, t)
    },
    resourcesAndVideoCheckDone: function () {

        var t = _.debounce((function () {
            app.vent.trigger("resize-started")
        }), 500, !0),
            n = _.debounce((function () {
                app.vent.trigger("resize-ended")
            }), 500),
            r = _.throttle(this.onResize, 133.3333328);
        app.vent.trigger("resize-ended"),

            jQuery(".site_wrapper").css({
                visibility: "visible"
            }),
            jQuery("body").on("click", (function (t) {
                app.vent.trigger("document-click", t.target)
            })), window.app.vent.on("fonts-loaded", (function () {
                console.log("recalc on font load"),
                    app.vent.trigger("resize-started"),
                    app.vent.trigger("resize-ended")
            })),
            setTimeout(function () {
                jQuery(window).scrollTop(0),
                    window.app.state.pageLoaded = !0,
                    app.vent.trigger("page-loaded");
                var i = window.innerWidth;
                jQuery(window).on("resize.page", (function () {
                    var e = window.innerWidth;
                    e != i && (t(), n(), r()), i = e
                })), window.addEventListener("scroll", this.onScroll), r(), jQuery(window).on("load", r)
            }.bind(this), app.settings.isDesktop ? 200 : 1e3)
    },
    updateItemsPos: function () {
        this.scrollItems.forEach(function (t, e) {
            t.height = t.$el.height(), t.top = t.$el.offset().top
        }.bind(this))
    },
    updateSectionsPos: function () {
        this.scrollSections.forEach(function (t, e) {
            t.height = t.$el.height(), t.top = t.$el.offset().top
        }.bind(this))
    },
    updateLazyPos: function () {
        this.lazyImgs.forEach(function (t, e) {
            t.height = t.$el.height(), t.top = t.$el.offset().top
        }.bind(this))
    },
    onResize: function () {
        this.updateItemsPos(), this.updateSectionsPos(), this.updateLazyPos(), this.windowHeight = window.innerHeight, this.onScroll()
    },
    onScroll: function (t, e) {
        var n = window.pageYOffset,
            i = this,
            r = +new Date;
        this.lastScrollEvent + 500 < r && (this.updateItemsPos(), this.updateSectionsPos(), this.updateLazyPos()), this.lastScrollEvent = r;
        var o = function (t, e, n) {
            for (var i = t.listeners[e], r = 0; r < i.length; r++) i[r]({}, n)
        };
        i.scrollItems.forEach((function (t, e) {
            var r = (n - (t.top - i.windowHeight + t.offset)) / (t.height + i.windowHeight - t.offset),
                s = Math.min(Math.max(r, 0), 1),
                a = n + i.windowHeight - (t.top + t.height);
            t.screenPositionConstrained !== s && (o(t, "screen-position", {
                $el: t.$el,
                screenPosition: s,
                height: t.height
            }),
                t.screenPositionConstrained = s), o(t, "screen-bottom", {
                    $el: t.$el,
                    screenBottom: a,
                    height: t.height
                }),
                (t.screenPosition < 0 && r >= 0 || t.screenPosition > 1 && r <= 1) && "enter" !== t["data-scroll-state"] && (o(t, "screen-enter", {
                    $el: t.$el
                }),
                    t["data-scroll-state"] = "enter"),
                (t.screenPosition >= 0 && r < 0 || t.screenPosition <= 1 && r > 1) && "leave" !== t["data-scroll-state"] && (o(t, "screen-leave", {
                    $el: t.$el
                }), t["data-scroll-state"] = "leave"), t.screenPosition = r
        }));
        var s = i.scrollSections[0].theme,
            a = -99999999;
        return i.scrollSections.forEach((function (t, e) {
            var i = t.top - n - 78;
            i <= 0 && i > a && (s = t.theme, a = i)
        })), s != i.navbarTheme && (i.navbarTheme = s, "dark" == s ? app.views.topNav && app.views.topNav.setDarkMode() : app.views.topNav && app.views.topNav.setLightMode()), app.views.topNav && app.views.topNav.onScroll(n), i.lazyImgs.forEach((function (t, e) {
            t.loaded || t.top <= n + 2 * i.windowHeight && (t.loaded = !0, t.sizes && t.el.setAttribute("sizes", t.sizes), t.srcset && t.el.setAttribute("srcset", t.srcset), t.src && t.el.setAttribute("src", t.src))
        })), n
    }
})

export default baseThirteen;