import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import gsap from "gsap";
// var i = n(1),
// r = n(3);
// n(50), 

const indexNews = Backbone.View.extend({
el: ".IndexNews",
events: {
    "mouseenter .IndexNews-link a": "onLinkMouseEnter",
    "mousemove .IndexNews-link a": "onLinkMouseMove",
    "mouseleave .IndexNews-link a": "onLinkMouseLeave",
    "touchstart .IndexNews-link a": "onLinkTouchStart",
    "click .NewsList-more": "showMore"
    
},
initialize: function(t) {
    
    // console.log("indexNews")
    this.options = t || {}, _.bindAll(this, "onSideMarginChanged"), 
    app.vent.on("about-margin", this.onSideMarginChanged), 
    this.moveImage.__throttled = _.throttle(this.moveImage.bind(this), 30), 
    this.$(".IndexNews-title p").each(function(t, n) {
        var i = app.utils.showAnimation(jQuery(n));
        this.$(n).onCustom("screen-position", function(t, e) {
            e.screenPosition > 0 && (e.$el.offCustom("screen-position"), i.play())
        }.bind(this))
    }.bind(this)), 
    this.$("li").each(function(t, n) {
        var i = app.utils.showAnimation(this.$(n).find(".IndexNews-date span")),
            r = app.utils.splitMultilineToSeparateSpans({
                $container: jQuery(n).find(".IndexNews-link-inner"),
                className: "IndexNews-link-line",
                autoWidth: !0,
                transparent: !0
            });
            this.$(n).onCustom("screen-position", function(t, e) {
            e.screenPosition > 0 && (e.$el.offCustom("screen-position"), 
            i.play(), 
            app.utils.animateTextByLines(r, app.settings.animationDuration, app.settings.animationDelay, null, !1, 30, 0))
        }.bind(this))
    }.bind(this)), 
    Backbone.View.prototype.initialize.call(this, t)
},
showMore:function(){
    //jQuery('.IndexNews li.hide').slice(0,4).addClass("show").removeClass("hide").
    let delay=.3
    gsap.utils.toArray(".IndexNews li.hide").slice(0,4).forEach((el,index)=>{
        gsap.fromTo(el,{autoAlpha: 0,y:"50px"}, {autoAlpha: 1,y:"0px", duration: 1,delay:delay*index})
        el.classList.remove('hide')
    }); 
    
    if(jQuery('.IndexNews li.hide').length===0){
        jQuery(".NewsList-more").hide()
    }
},
onSideMarginChanged: function(t) {
    t = Math.max(t, 20), setTimeout(function() {
        this.$(".IndexNews-imageContainer").each((function(n, i) {
            jQuery(i).css("transform", "");
            var r = jQuery("body").width() - (jQuery(i).offset().left + jQuery(i).width());
            r < t && jQuery(i).css("transform", "translateX(" + -(t - r) + "px)")
        }))
    }.bind(this), 500)
},
onLinkMouseEnter: function(t) {
    app.settings.isMobile || jQuery(t.target).closest("li").find(".IndexNews-imageContainer").addClass("show")
},
onLinkTouchStart: function(t) {
    if (!app.settings.isDesktop) {
        var n = jQuery(t.target).closest("li").find(".IndexNews-imageContainer");
        n.addClass("show");
        var i = function(t) {
            t.originalEvent.touches.length && "touchcancel" != t.type || (n.removeClass("show"), jQuery("body").off("touchend touchcancel", i))
        };
        jQuery("body").on("touchend touchcancel", i)
    }
},
onLinkMouseMove: function(t) {
    this.moveImage.__throttled(t)
},
moveImage: function(t) {
    var n = jQuery(t.currentTarget),
        i = n.closest("li").find(".IndexNews-imageContainer img"),
        r = n.offset(),
        o = r.left,
        s = r.top,
        a = n.width(),
        l = n.height(),
        u = (t.pageX - o) / a,
        c = (t.pageY - s) / l;
    window.innerWidth < 1024 ? i.css("transform", "translate(" + 30 * u + "px, " + 20 * c + "px)") : i.css("transform", "translate(" + 60 * u + "px, " + 40 * c + "px)")
},
onLinkMouseLeave: function(t) {
    app.settings.isMobile || jQuery(t.target).closest("li").find(".IndexNews-imageContainer").removeClass("show")
}
})

export default indexNews