import jQuery from "jquery";
import _ from "underscore";
// var i = n(1),
// r = n(21);
const ajaxSetup ={
configure: function() {
    jQuery.ajaxSetup({
        beforeSend: function(t, e) {
            var n, i;
            i = e.type, /^(GET|HEAD|OPTIONS|TRACE)$/.test(i) || this.crossDomain || (n = r.get("csrftoken"), t.setRequestHeader("X-CSRFToken", n))
        }
    }), Backbone.Model.prototype.toJSON = function() {
        var t, e = _.clone(this.attributes);
        for (t in e)(e[t] instanceof Backbone.Model || e[t] instanceof Backbone.Collection) && (e[t] = e[t].toJSON());
        return e
    }
}
}

export default ajaxSetup