import _ from "underscore";
import Backbone from "backbone";
// n(1);
// var i = n(3);
// n(38);
// n(7).TimelineLite,
// n(8).Expo, 
// n(9).CSSPlugin;
const ServicesType = Backbone.View.extend({
    el: ".Services-type-section",
    events: {},
    initialize: function(t) {
        var n = +new Date;
        app.vent.on("page-loaded", function() {
            n = +new Date
        }.bind(this)), this.$('[data-animation-type="image"]').each(function(t, i) {
            var r = app.utils.showAnimation(this.$(i)),
                o = !1,
                s = this.$(i).find("img");
                this.$(i).onCustom("screen-position", function(t, e) {
                if (app.settings.isDesktop) {
                    var i = app.utils.easingFunctions(0, 1, e.screenPosition, "easeOutQuad"),
                        a = Math.round(50 - 100 * i);
                    s.css({
                        transform: "translate3d(0, " + a + "px, 0)"
                    })
                }
                e.screenPosition > 0 && !o && (o = !0, r.delay((+new Date < n + app.settings.animationDelay ? 4 * app.settings.animationDelay + 180 : 0) / 1e3), r.play())
            }.bind(this))
        }.bind(this)), this.$('[data-animation-type="title"]').each(function(t, i) {
            // var r = app.utils.splitMultilineToSeparateSpans({
            //     $container: this.$(i).find("p"),
            //     className: "AboutWhatWeDo-title-line",
            //     transparent: !0
            // });
            this.$(i).onCustom("screen-position", function(t, e) {
                if (e.screenPosition > 0) {
                    e.$el.offCustom("screen-position");
                    var i = +new Date < n + app.settings.animationDelay ? 4 * app.settings.animationDelay + 180 : 0;
                    // app.utils.animateTextByLines(r, app.settings.animationDuration, app.settings.animationDelay, null, !1, 30, i)
                }
            }.bind(this))
        }.bind(this)), this.$('[data-animation-type="text"]').each(function(t, i) {
            // var r = app.utils.splitMultilineToSeparateSpans({
            //     $container: this.$(i).find("p"),
            //     className: "AboutWhatWeDo-text-line",
            //     transparent: !0
            // });
            this.$(i).onCustom("screen-position", function(t, e) {
                if (e.screenPosition > 0) {
                    e.$el.offCustom("screen-position");
                    var i = +new Date < n + app.settings.animationDelay ? 4 * app.settings.animationDelay + 180 : 0;
                    i += 2 * app.settings.animationDelay; 
                    // app.utils.animateTextByLines(r, app.settings.animationDuration, app.settings.animationDelay, null, !1, 30, i)
                }
            }.bind(this))
        }.bind(this)), Backbone.View.prototype.initialize.call(this, t)
    }
})
export default ServicesType;