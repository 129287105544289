import _ from "underscore";
import Backbone from "backbone";
// var i = n(1),
//             r = n(3);
//         n(27), 
        
const AboutAwards = Backbone.View.extend({
            el: ".AboutAwards",
            events: {
                "click #designrush": "onDesignrushClick",
                "click #orpetron": "onOrpetronClick",
                "click #current": "onCurrentClick",
                "click #awwwards": "onAwwwardsClick",
                "click #shopifypartner": "onShopifypartnerClick"
            },
            initialize: function(t) {
                _.bindAll(this, "onDocumentClick"), 
                this.isDesignrushActive = !1, 
                this.isOrpetronActive = !1, 
                this.isCurrentActive = !1, 
                this.isAwwwardsActive = !1, 
                this.isShopifypartnerActive = !1, 
                this.isNotPhonePort = !1, 
                app.vent.on("document-click", this.onDocumentClick);
                var e = this,
                    n = window.screen.orientation && "landscape-primary" === window.screen.orientation.type || window.orientation && (90 === window.orientation || -90 === window.orientation);
                e.isNotPhonePort = app.settings.isDesktop || app.settings.isTablet || app.settings.isMobile && n, 
                // e.isNotPhonePort && (
                
                // this.$("#designrush").on("mouseenter touchstart", (function() {
                //         console.log(".svg--designrush")
                //     e.isDesignrushActive || (e.$("#designrush-wrapper").addClass("show"), e.$("#designrush-1").addClass("show"), e.upLogo(".svg--designrush"))
                // })), 
                // this.$("#designrush").on("mouseleave touchmove", (function() {
                //     e.$("#designrush-1").removeClass("show"), e.downLogo(".svg--designrush"), setTimeout((function() {
                //         e.$("#designrush-wrapper").removeClass("show")
                //     }), 100), e.downLogo(".svg--designrush"), e.isDesignrushActive = !1
                // })), 
                // this.$("#orpetron").on("mouseenter touchstart", (function() {
                //     console.log(".svg--orpetron")
                //     e.isDesignrushActive || (e.$("#orpetron-wrapper").addClass("show"), e.$("#orpetron-1").addClass("show"), e.$("#orpetron-2").addClass("show"), e.$("#orpetron-3").addClass("show"), e.$("#orpetron-4").addClass("show"), e.upLogo(".svg--orpetron"))
                // })), 
                // this.$("#orpetron").on("mouseleave touchmove", (function() {
                //     e.$("#orpetron-1").removeClass("show"), e.$("#orpetron-2").removeClass("show"), e.$("#orpetron-3").removeClass("show"), e.$("#orpetron-4").removeClass("show"), e.downLogo(".svg--orpetron"), setTimeout((function() {
                //         e.$("#orpetron-wrapper").removeClass("show")
                //     }), 100), e.downLogo(".svg--orpetron"), e.isDesignrushActive = !1
                // })),  
                // this.$("#current").on("mouseenter touchstart", (function() {
                //     e.isCurrentActive || (e.$("#current-wrapper").addClass("show"), e.$("#current-1").addClass("show"), e.$("#current-2").addClass("show"), e.$("#current-3").addClass("show"), e.$("#current-4").addClass("show"), e.upLogo(".svg--current"))
                // })), 
                // this.$("#current").on("mouseleave touchmove", (function() {
                //     e.$("#current-1").removeClass("show"), e.$("#current-2").removeClass("show"), e.$("#current-3").removeClass("show"), e.$("#current-4").removeClass("show"), setTimeout((function() {
                //         e.$("#current-wrapper").removeClass("show")
                //     }), 100), e.downLogo(".svg--current"), e.isCurrentActive = !1
                // })), 
                // this.$("#awwwards").on("mouseenter touchstart", (function() {
                //     e.isAwwwardsActive || (e.$("#awwwards-wrapper").addClass("show"), e.$("#awwwards-1").addClass("show"), e.$("#awwwards-2").addClass("show"), e.$("#awwwards-3").addClass("show"), e.$("#awwwards-4").addClass("show"), e.upLogo(".svg--awwwards"))
                // })), 
                // this.$("#awwwards").on("mouseleave touchmove", (function() {
                //     e.$("#awwwards-1").removeClass("show"), e.$("#awwwards-2").removeClass("show"), e.$("#awwwards-3").removeClass("show"), e.$("#awwwards-4").removeClass("show"), setTimeout((function() {
                //         e.$("#awwwards-wrapper").removeClass("show")
                //     }), 100), e.downLogo(".svg--awwwards"), e.isAwwwardsActive = !1
                // })), 
                // this.$("#shopifypartner").on("mouseenter", (function() {
                //     e.isShopifypartnerActive || (e.$("#shopifypartner-wrapper").addClass("show"), e.$("#shopifypartner-1").addClass("show"), e.$("#shopifypartner-2").addClass("show"), e.$("#shopifypartner-3").addClass("show"), e.$("#shopifypartner-4").addClass("show"), e.upLogo(".svg--shopifypartner"))
                // })), 
                // this.$("#shopifypartner").on("mouseleave touchmove", (function() {
                //     e.$("#shopifypartner-1").removeClass("show"), e.$("#shopifypartner-2").removeClass("show"), e.$("#shopifypartner-3").removeClass("show"), e.$("#shopifypartner-4").removeClass("show"), setTimeout((function() {
                //         e.$("#shopifypartner-wrapper").removeClass("show")
                //     }), 100), e.downLogo(".svg--shopifypartner"), e.isShopifypartnerActive = !1
                // }))), 
                Backbone.View.prototype.initialize.call(this, t)
            },
            onDesignrushClick: function() {
                this.isNotPhonePort && (this.isDesignrushActive ? (this.$("#designrush-1").removeClass("show"), this.$("#designrush-2").removeClass("show"), this.$("#designrush-3").removeClass("show"), this.$("#designrush-4").removeClass("show"), setTimeout(function() {
                    this.$("#designrush-wrapper").removeClass("show")
                }.bind(this), 100), this.downLogo(".svg--designrush")) : (this.$("#designrush-wrapper").addClass("show"), this.$("#designrush-1").addClass("show"), this.$("#designrush-2").addClass("show"), this.$("#designrush-3").addClass("show"), this.$("#designrush-4").addClass("show"), this.upLogo(".svg--designrush")), this.isDesignrushActive = !this.isDesignrushActive)
            },
            onOrpetronClick: function() {
                this.isNotPhonePort && (this.isOrpetronActive ? (this.$("#orpetron-1").removeClass("show"), this.$("#orpetron-2").removeClass("show"), this.$("#orpetron-3").removeClass("show"), this.$("#orpetron-4").removeClass("show"), setTimeout(function() {
                    this.$("#orpetron-wrapper").removeClass("show")
                }.bind(this), 100), this.downLogo(".svg--orpetron-logo")) : (this.$("#orpetron-wrapper").addClass("show"), this.$("#orpetron-1").addClass("show"), this.$("#orpetron-2").addClass("show"), this.$("#orpetron-3").addClass("show"), this.$("#orpetron-4").addClass("show"), this.upLogo(".svg--orpetron-logo")), this.isOrpetronActive = !this.isOrpetronActive)
            },
            onCurrentClick: function() {
                this.isNotPhonePort && (this.isCurrentActive ? (this.$("#current-1").removeClass("show"), this.$("#current-2").removeClass("show"), this.$("#current-3").removeClass("show"), this.$("#current-4").removeClass("show"), setTimeout(function() {
                    this.$("#current-wrapper").removeClass("show")
                }.bind(this), 100), this.downLogo(".svg--current")) : (this.$("#current-wrapper").addClass("show"), this.$("#current-1").addClass("show"), this.$("#current-2").addClass("show"), this.$("#current-3").addClass("show"), this.$("#current-4").addClass("show"), this.upLogo(".svg--current")), this.isCurrentActive = !this.isCurrentActive)
            },
            onAwwwardsClick: function() {
                this.isNotPhonePort && (this.isAwwwardsActive ? (this.$("#awwwards-1").removeClass("show"), this.$("#awwwards-2").removeClass("show"), this.$("#awwwards-3").removeClass("show"), this.$("#awwwards-4").removeClass("show"), setTimeout(function() {
                    this.$("#awwwards-wrapper").removeClass("show")
                }.bind(this), 100), this.downLogo(".svg--awwwards")) : (this.$("#awwwards-wrapper").addClass("show"), this.$("#awwwards-1").addClass("show"), this.$("#awwwards-2").addClass("show"), this.$("#awwwards-3").addClass("show"), this.$("#awwwards-4").addClass("show"), this.upLogo(".svg--awwwards")), this.isAwwwardsActive = !this.isAwwwardsActive)
            },
            onShopifypartnerClick: function() {
                console.log("onShopifypartnerClick")
                this.isNotPhonePort && (this.isShopifypartnerActive ? (this.$("#shopifypartner-1").removeClass("show"), this.$("#shopifypartner-2").removeClass("show"), this.$("#shopifypartner-3").removeClass("show"), this.$("#shopifypartner-4").removeClass("show"), setTimeout(function() {
                    this.$("#shopifypartner-wrapper").removeClass("show")
                }.bind(this), 100), this.downLogo(".svg--shopifypartner")) : (this.$("#shopifypartner-wrapper").addClass("show"), this.$("#shopifypartner-1").addClass("show"), this.$("#shopifypartner-2").addClass("show"), this.$("#shopifypartner-3").addClass("show"), this.$("#shopifypartner-4").addClass("show"), this.upLogo(".svg--shopifypartner")), this.isShopifypartnerActive = !this.isShopifypartnerActive)
            },
            onDocumentClick: function() {},
            upLogo: function(t) {

                this.$(t).css("opacity", "1"), this.$(t).css("transform", "translateY(-20px)"), this.$(t).css("transition", "fill 450ms, transform 450ms, opacity 450ms")
            },
            downLogo: function(t) {
                this.$(t).css("opacity", "0.5"), this.$(t).css("transform", "translateY(0)"), this.$(t).css("transition", "fill 630ms, transform 630ms, opacity 630ms")
            }
        })

export default AboutAwards;