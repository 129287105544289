import _ from "underscore"
import baseThirteen from  "../../base/baseThirteen";

var aboutView = baseThirteen.extend({
    el: ".About",
    events: {},
    initialize: function(t) {
        baseThirteen.prototype.initialize.call(this, t), 
        this.$(".About-title").onCustom("screen-position", (function(t, e) {
            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        }));
        this.$(".About-subtitle").onCustom("screen-position", (function(t, e) {
            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        }));
        app.settings.animationDelay;
        //this.$(".About-subtitle").each(function(t, n) {
            // var i = app.utils.splitMultilineToSeparateSpans({
            //         $container: jQuery(n).find("h1"),
            //         className: "About-subtitle-line",
            //         transparent: !0
            //     }),
            //     r = app.utils.splitMultilineToSeparateSpans({
            //         $container: jQuery(n).find("p"),
            //         className: "About-subtitle-line",
            //         transparent: !0
            //     });
            //     this.$(n).onCustom("screen-position", function(t, e) {
            //     if (e.screenPosition > 0) {
            //         e.$el.offCustom("screen-position");
            //         var n = app.settings.animationDelay + 180,
            //             o = app.utils.animateTextByLines(i, app.settings.animationDuration, app.settings.animationDelay, null, !1, 30, n);
            //         app.utils.animateTextByLines(r, app.settings.animationDuration, app.settings.animationDelay, null, !1, 30, o * app.settings.animationDelay + n)
            //     }
            // }.bind(this))
        //}.bind(this))
        // this.$(".svg--hand").onCustom("screen-position", (function(t, e) {
        //     e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
        // }))
    }
})
export default aboutView