function e(t, e) {
    document.addEventListener ? t.addEventListener("scroll", e, !1) : t.attachEvent("scroll", e)
}

function n(t) {
    this.a = document.createElement("div"), this.a.setAttribute("aria-hidden", "true"), this.a.appendChild(document.createTextNode(t)), this.b = document.createElement("span"), this.c = document.createElement("span"), this.h = document.createElement("span"), this.f = document.createElement("span"), this.g = -1, this.b.style.cssText = "max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;", this.c.style.cssText = "max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;", this.f.style.cssText = "max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;", this.h.style.cssText = "display:inline-block;width:200%;height:200%;font-size:16px;max-width:none;", this.b.appendChild(this.h), this.c.appendChild(this.f), this.a.appendChild(this.b), this.a.appendChild(this.c)
}

function i(t, e) {
    t.a.style.cssText = "max-width:none;min-width:20px;min-height:20px;display:inline-block;overflow:hidden;position:absolute;width:auto;margin:0;padding:0;top:-999px;white-space:nowrap;font-synthesis:none;font:" + e + ";"
}

function r(t) {
    var e = t.a.offsetWidth,
        n = e + 100;
    return t.f.style.width = n + "px", t.c.scrollLeft = n, t.b.scrollLeft = t.b.scrollWidth + 100, t.g !== e && (t.g = e, !0)
}

function o(t, n) {
    function i() {
        var t = o;
        r(t) && t.a.parentNode && n(t.g)
    }
    var o = t;
    e(t.b, i), e(t.c, i), r(t)
}

function base84(t, e) {
    var n = e || {};
    this.family = t, this.style = n.style || "normal", this.weight = n.weight || "normal", this.stretch = n.stretch || "normal"
}
var a = null,
    l = null,
    u = null,
    c = null;

function h() {
    return null === c && (c = !!document.fonts), c
}

function p() {
    if (null === u) {
        var t = document.createElement("div");
        try {
            t.style.font = "condensed 100px sans-serif"
        } catch (t) {}
        u = "" !== t.style.font
    }
    return u
}

function f(t, e) {
    return [t.style, t.weight, p() ? t.stretch : "", "100px", e].join(" ")
}
base84.prototype.load = function(t, e) {
    var r = this,
        s = t || "BESbswy",
        u = 0,
        c = e || 3e3,
        p = (new Date).getTime();
    return new Promise((function(t, e) {
        if (h() && ! function() {
                if (null === l)
                    if (h() && /Apple/.test(window.navigator.vendor)) {
                        var t = /AppleWebKit\/([0-9]+)(?:\.([0-9]+))(?:\.([0-9]+))/.exec(window.navigator.userAgent);
                        l = !!t && 603 > parseInt(t[1], 10)
                    } else l = !1;
                return l
            }()) {
            var d = new Promise((function(t, e) {
                    ! function n() {
                        (new Date).getTime() - p >= c ? e(Error(c + "ms timeout exceeded")) : document.fonts.load(f(r, '"' + r.family + '"'), s).then((function(e) {
                            1 <= e.length ? t() : setTimeout(n, 25)
                        }), e)
                    }()
                })),
                m = new Promise((function(t, e) {
                    u = setTimeout((function() {
                        e(Error(c + "ms timeout exceeded"))
                    }), c)
                }));
            Promise.race([m, d]).then((function() {
                clearTimeout(u), t(r)
            }), e)
        } else ! function(t) {
            document.body ? t() : document.addEventListener ? document.addEventListener("DOMContentLoaded", (function e() {
                document.removeEventListener("DOMContentLoaded", e), t()
            })) : document.attachEvent("onreadystatechange", (function e() {
                "interactive" != document.readyState && "complete" != document.readyState || (document.detachEvent("onreadystatechange", e), t())
            }))
        }((function() {
            function l() {
                var e;
                (e = -1 != v && -1 != g || -1 != v && -1 != y || -1 != g && -1 != y) && ((e = v != g && v != y && g != y) || (null === a && (e = /AppleWebKit\/([0-9]+)(?:\.([0-9]+))/.exec(window.navigator.userAgent), a = !!e && (536 > parseInt(e[1], 10) || 536 === parseInt(e[1], 10) && 11 >= parseInt(e[2], 10))), e = a && (v == w && g == w && y == w || v == A && g == A && y == A || v == b && g == b && y == b)), e = !e), e && (x.parentNode && x.parentNode.removeChild(x), clearTimeout(u), t(r))
            }
            var h = new n(s),
                d = new n(s),
                m = new n(s),
                v = -1,
                g = -1,
                y = -1,
                w = -1,
                A = -1,
                b = -1,
                x = document.createElement("div");
            x.dir = "ltr", i(h, f(r, "sans-serif")), i(d, f(r, "serif")), i(m, f(r, "monospace")), x.appendChild(h.a), x.appendChild(d.a), x.appendChild(m.a), document.body.appendChild(x), w = h.a.offsetWidth, A = d.a.offsetWidth, b = m.a.offsetWidth,
                function t() {
                    if ((new Date).getTime() - p >= c) x.parentNode && x.parentNode.removeChild(x), e(Error(c + "ms timeout exceeded"));
                    else {
                        var n = document.hidden;
                        !0 !== n && void 0 !== n || (v = h.a.offsetWidth, g = d.a.offsetWidth, y = m.a.offsetWidth, l()), u = setTimeout(t, 50)
                    }
                }(), o(h, (function(t) {
                    v = t, l()
                })), i(h, f(r, '"' + r.family + '",sans-serif')), o(d, (function(t) {
                    g = t, l()
                })), i(d, f(r, '"' + r.family + '",serif')), o(m, (function(t) {
                    y = t, l()
                })), i(m, f(r, '"' + r.family + '",monospace'))
        }))
    }))
};

export default base84