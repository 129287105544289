import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import gsap from "gsap";
import topMenu from "./topMenu.js";
import baseThree from "./base/baseThree.js";



const topLogo =  Backbone.View.extend({
    events: {
        "click .siteHeader-logo": "onLogoClick"
        
    },
    initialize: function(t) {
        this.options = t || {},
        _.bindAll(this, "onPageLoad", "onThemeChange","onScroll"), 
        this.menu = new topMenu({
            page: this
        }), 
        this.logoScale = 1, 
        this.logo = this.$(".siteHeader-logo"), 
        this.logoIcon = this.$(".siteHeader-logo .logo-icon"), 
        app.vent.on("page-loaded", this.onPageLoad), 
        app.vent.on("theme-change", this.onThemeChange), 
        this.theme = "light", 
        baseThree.prototype.initialize.call(this, t)
        jQuery(window).on("scroll",this.onScroll);
    },
    onScroll: function(t) {
        //console.log("onScroll")
        var e = Math.min(Math.max(jQuery(window).scrollTop() - (app.settings.isPhone ? 0 : 90), 0), 90) / 90,
            n = app.settings.isPhone ? .74 : .64,
            i = 1 - e * (1 - n);
        i < 1 && (i = n),
        i != this.logoScale && (
            this.logo.toggleClass('shrink'),
            this.logo.closest('.siteHeader').toggleClass('sticky'),
            gsap.to(this.logo, {
                 scale: i }), 
            this.logoScale = i
            )
    },
    onLogoClick: function() {
        var t = window.pageYOffset;
        jQuery("main").hasClass("Index") ? t > 0 && !this.scrollingToTop && (this.scrollingToTop = !0, jQuery("body, html").animate({
            scrollTop: 0
        }, 600, function() {
            this.scrollingToTop = !1
        }.bind(this))) : window.location.href = gga.baseurl
    },
    onPageLoad: function() {
        this.logoIcon.addClass("stop-loader")
    },
    setDarkMode: function() {
        "dark" != this.theme && (this.el.classList.add("dark-theme"), this.theme = "dark")
    },
    setLightMode: function() {
        "light" != this.theme && (this.el.classList.remove("dark-theme"), this.theme = "light")
    },
    onThemeChange: function() {}
})

export default topLogo