import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import YouTubePlayer from 'youtube-player';

// var i = n(1),
//                 r = n(3);
//             n(56);//nothing
//             var o = n(57);
            const videoHead = Backbone.View.extend({
                el: ".gga_video_wrapper",
                events: {
                    "click .gga_video-reel-inner": "onPlayClick",
                    "click .gga_video-popup-video": "onVideoClose"
                },
                initialize: function(t) {
                    var n = this;
                    this.options = t || {}, _.bindAll(this, "onPageLoaded", "onResize", "getReelParalaxDuration", "playLoopVideo", "resumeLoop", "pauseLoop", "setOnScrollPopup", "onScrollPopup"), this.indexPageMode = this.$el.hasClass("VideoHead--darkThemeOnReel");

                    this.$(".gga_video_header").onCustom("screen-position", (function(t, e) {
                        +new Date, e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
                    })), 
                    this.$(".gga_video_reels").onCustom("screen-position", function(t, e) {
                        if (e.screenPosition > 0) {
                            e.$el.offCustom("screen-position"), e.$el.addClass("show");
                            var i = 1e3 * parseFloat(e.$el.css("transition-duration").split(",")[0]),
                                r = 1e3 * parseFloat(e.$el.css("transition-delay").split(",")[0]);
                            setTimeout((function() {
                                
                                n.shown = !0, 
                                // n.entered && n.resumeLoop(), 
                                n.resumeLoop(), 
                                e.$el.addClass("shown")
                                
                            }.bind(this)), i + r + 100)
                        }
                    }.bind(this));
                    
                    this.$(".gga_video-reel").onCustom("screen-enter", (function(t, e) {
                        n.entered = !0, 
                        n.shown && n.resumeLoop()
                        console.log("screen-enter",n.entered)
                    })), 
                    this.$(".gga_video-reel").onCustom("screen-leave", (function(t, e) {
                        n.entered = !1, 
                        n.shown && n.pauseLoop()
                        console.log("screen-leave",n.entered)
                    })), 
                    jQuery(window).on("resize", this.onResize), 
                    this.onResize(), 
                    app.vent.on("page-loaded", this.onPageLoaded), 
                    Backbone.View.prototype.initialize.call(this, t)
                },
                onPageLoaded: function() {
                    this.windowScrollTop = jQuery(window).scrollTop();
                    var t = jQuery('<video muted playsinline crossorigin loop preload="auto" poster="' + this.$(".gga_video_reels").attr("data-video-poster") + '" src="' + this.$(".gga_video_reels").attr("data-video") + '"></video>');
                    t.insertBefore(this.$(".gga_video-reel-inner .gga_video-reel-overlay")), 
                    this.video = t.get(0), 
                    this.video && this.video.addEventListener("ended", function() {
                        this.video.play()
                    }.bind(this), !1)
                   
                },
                onResize: function() {
                    for (var t = document.body.clientWidth, n = (jQuery(window).height(), [{
                            ww: 0,
                            margin: -249
                        }, {
                            ww: 498,
                            margin: 0
                        }, {
                            ww: 542,
                            margin: 22
                        }, {
                            ww: 667,
                            margin: 22
                        }, {
                            ww: 671,
                            margin: 24
                        }, {
                            ww: 768,
                            margin: 24
                        }, {
                            ww: 786,
                            margin: 33
                        }, {
                            ww: 1024,
                            margin: 33
                        }, {
                            ww: 1038,
                            margin: 40
                        }, {
                            ww: 1152,
                            margin: 40
                        }, {
                            ww: 1162,
                            margin: 45
                        }, {
                            ww: 1280,
                            margin: 45
                        }, {
                            ww: 1440,
                            margin: 45
                        }, {
                            ww: 1e4,
                            margin: 4325
                        }]), i = n.length - 1; i >= 0 && !(n[i].ww <= t); i--);
                    var r = n[i],
                        o = n[i + 1],
                        s = (t - r.ww) / (o.ww - r.ww),
                        a = (o.margin - r.margin) * s + r.margin,
                        l = t - 2 * a;
                    this.$(".gga_video-reel").css({
                        width: Math.round(l),
                        "margin-left": Math.round(a)
                    }), this.setYTVideoSizeAndPos(a), this.reelBottomPos = this.$(".gga_video_reels").offset().top + this.$(".gga_video-reel").height()
                },
                setYTVideoSizeAndPos: function(t) {
                    var n, i, r = this.$(".gga_video-popup-video-container"),
                        o = this.$(".gga_video-popup-video-placeholder"),
                        s = r.width(),
                        a = r.height();
                    s / a < 16 / 9 ? (n = s, i = s / (16 / 9)) : (n = a * (16 / 9), i = a);
                    var l = -(n - s) / 2,
                        u = -(i - a) / 2;
                    o.css({
                        left: l,
                        top: u,
                        width: n,
                        height: i
                    }), this.$("#player-mobile").css({
                        width: Math.round(jQuery(window).width()),
                        left: Math.round(-1 * t)
                    })
                },
                resumeLoop: function() {
                    app.settings.isMobile ? app.settings.canAutoplay || this.videoReady ? this.playLoopVideo() : jQuery("body").one("touchstart", this.playLoopVideo) : this.playLoopVideo()
                },
                playLoopVideo: function() {
                    this.video.play(), this.checkPlayInterval = setInterval(function() {
                        this.video.currentTime > 0 && (clearInterval(this.checkPlayInterval), this.$(".gga_video-reel img").addClass("hidden"), setTimeout(function() {
                            this.$(".gga_video-reel img").remove()
                        }.bind(this), 500))
                    }.bind(this), 30), this.videoReady = !0
                },
                pauseLoop: function() {
                    jQuery("body").off("touchstart", this.playLoopVideo), clearInterval(this.checkPlayInterval), this.videoReady && this.video.pause()
                },
                getReelParalaxDuration: function() {
                    return this.reelBottomPos
                },
                onPlayClick: function() {
                    if (!this.isPlayClicked) {
                        if (clearTimeout(this.stopTimeout), clearInterval(this.volumeInterval), !this.player) {
                            app.settings.isDesktop && (this.$(".gga_video-popup-video").show(), this.onResize());
                            var t = app.settings.isDesktop ? "player" : "player-mobile";
                            this.player = YouTubePlayer(t, {
                                videoId: "IdSIBYeXx-w",
                                playerVars: {
                                    autoplay: 0,
                                    controls: 1,
                                    autohide: 1,
                                    wmode: "opaque",
                                    origin: "https://globalgrid.agency"
                                }
                            }), this.player.on("ready", (function(t) {
                                t.target.playVideo()
                            }));
                            var e = this;
                            app.settings.isDesktop || this.player.on("stateChange", (function(t) {
                                t && 2 === t.data ? e.videoPauseTimeout = setTimeout((function() {
                                    e.onVideoPauseMobile()
                                }), 100) : e.videoPauseTimeout && clearTimeout(e.videoPauseTimeout)
                            }))
                        }
                        this.isPlayClicked = !0, this.$(".gga_video-reel-innerPlay").flashClass("pushed", 170), app.settings.isDesktop ? (void 0 !== this.lastVolume && this.player.setVolume(this.lastVolume), 
                        this.$(".gga_video-reel-overlay").show(), 
                        setTimeout(function() {
                            this.$(".gga_video-reel-overlay").addClass("show")
                        }.bind(this), 30), 
                        this.player.playVideo(), 
                        this.pauseLoop(), 
                        this.setDarkTheme(), 
                        setTimeout(function() {
                            this.setOnScrollPopup(), 
                            this.$(".gga_video-popup-video").addClass("show"), 
                            this.indexPageMode || this.disableGlobalScroll(), 
                            this.isPlayClicked = !1
                        }.bind(this), 300)) : (this.$(".gga_video-reel-inner").css("visibility", "hidden"), this.$("#player-mobile").addClass("show"), this.player.playVideo(), this.pauseLoop())
                    }
                },
                setOnScrollPopup: function() {
                    this.windowScrollTop = jQuery(window).scrollTop() 
                    jQuery(window).on("scroll", this.onScrollPopup)
                },
                onScrollPopup: function(t) {
                    if (this.indexPageMode && this.$(".gga_video-popup-video").hasClass("show") && jQuery(window).scrollTop() > this.windowScrollTop + 100) {
                        this.setDarkTheme();
                        var n = this;
                        setTimeout((function() {
                            n.onVideoClose(), n.setDarkTheme()
                        }), 500)
                    }
                },
                onVideoClose: function(t) {
                    clearTimeout(this.stopTimeout), 
                    clearInterval(this.volumeInterval), 
                    this.$(".gga_video-popup-video").removeClass("show"), 
                    this.resumeLoop(), 
                    jQuery(window).off("scroll", this.onScrollPopup), 
                    this.$(".gga_video-reel-overlay").removeClass("show"), 
                    setTimeout(function() {
                        this.$(".gga_video-reel-overlay").hide()
                    }.bind(this), 1e3);
                    var n = this;
                    if (this.indexPageMode || this.enableGlobalScroll(), app.settings.isDesktop) {
                        var i = +new Date,
                            r = i + 350;
                        this.resetDarkTheme(), this.player.getVolume().then((function(t) {
                            n.lastVolume = t, n.volumeInterval = setInterval((function() {
                                var e = +new Date,
                                    o = r - i;
                                if (e = Math.min(Math.max(e, i), r), o) var s = (e - i) / o;
                                else s = 1;
                                s = 1 - s, n.player.setVolume(Math.round(t * s)), 0 === s && clearInterval(n.volumeInterval)
                            }), 17)
                        }))
                    }
                    this.stopTimeout = setTimeout((function() {
                        n.player.stopVideo()
                    }), 350)
                },
                disableGlobalScroll: function() {
                    jQuery("html").css("overflow", "hidden")
                },
                enableGlobalScroll: function() {
                    jQuery("html").css("overflow", "")
                },
                setDarkTheme: function() {
                    this.indexPageMode && (e("html").addClass("dark-theme").flashClass("dark-theme-transition", 1500), app.vent.trigger("theme-change", "dark"))
                },
                resetDarkTheme: function() {
                    this.indexPageMode && (e("html").removeClass("dark-theme").flashClass("dark-theme-transition", 1500), app.vent.trigger("theme-change", "light"))
                },
                onVideoPauseMobile: function() {
                    this.isPlayClicked = !1, this.$(".gga_video-reel-inner").css("visibility", "visible"), this.$("#player-mobile").removeClass("show"), this.resumeLoop()
                }
            })
            export default videoHead