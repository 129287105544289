import jQuery from "jquery";
import _ from "underscore";
import Backbone from "backbone";
// var i = n(1),
//                 r = n(3);
// n(31), 

const aboutGallery = Backbone.View.extend({
    el: ".gga_gallery",
    events: {
        // "mouseenter .AboutGallery-right-hover-area": "onMouseenterRight",
        // "mouseleave .AboutGallery-right-hover-area": "onMouseleave",
        // "mouseenter .AboutGallery-left-hover-area": "onMouseenterLeft",
        // "mouseleave .AboutGallery-left-hover-area": "onMouseleave",
        "mouseenter .gga_gallery_right_hover_area": "onMouseenterRight",
        "mouseleave .gga_gallery_right_hover_area": "onMouseleave",
        "mouseenter .gga_gallery_left_hover_area": "onMouseenterLeft",
        "mouseleave .gga_gallery_left_hover_area": "onMouseleave"
    },
    initialize: function(t) {
        _.bindAll(this, "makeShiftStep", "stopShiftAnimation"), this.$wrapper = this.$(".gga_gallery_shift_wrapper"), this.shiftData = {
            val: 0,
            delta: 0
        };
        var n = this;
        jQuery(window).on("resize", this.onResize.bind(this)), 
        app.settings.isDesktop && this.$(".gga_gallery_shift_wrapper").onCustom("screen-position", function(t, e) {
            e.screenPosition > 0 && (e.$el.offCustom("screen-position"), this.startShiftAnimation(-1, 8), this.stopFadeTimeout = setTimeout(this.stopShiftAnimation, 900))
        }.bind(this)), 
        jQuery(document).ready((function() {
            n.cycleLength = Math.ceil(this.$(".gga_gallery_img_wrapper").width() / 3), app.settings.isDesktop && n.$wrapper.css("transform", "translate3d(" + n.cycleLength + "px, 0, 0)")
        }.bind(this)))
    },
    onMouseenterRight: function() {
        app.settings.isDesktop && this.startShiftAnimation(-1, 4)
    },
    onMouseenterLeft: function() {
        app.settings.isDesktop && this.startShiftAnimation(1, 4)
    },
    onMouseleave: function() {
        app.settings.isDesktop && this.stopShiftAnimation()
    },
    startShiftAnimation: function(t, e) {
        clearTimeout(this.stopFadeTimeout), clearTimeout(this.stopTimeout), window.cancelAnimationFrame(this.shiftData.frameID), this.shiftData.stop = !1, this.shiftData.dir = t, this.shiftData.speed = e, this.makeShiftStep()
    },
    stopShiftAnimation: function() {
        clearTimeout(this.stopTimeout), this.shiftData.stop = !0;
        var t = this;
        this.stopTimeout = setTimeout(function() {
            window.cancelAnimationFrame(t.shiftData.frameID), t.shiftData.delta = 0
        }.bind(this), 2e3)
    },
    makeShiftStep: function() {
        this.shiftData.delta += .01 * (this.shiftData.stop ? -5 : 2), this.shiftData.delta = Math.min(Math.max(this.shiftData.delta, 0), 2), this.shiftData.val += this.shiftData.dir * Math.pow(this.shiftData.delta, .9) * this.shiftData.speed, this.shiftData.val = (this.shiftData.val + this.cycleLength) % this.cycleLength, this.$wrapper.css("transform", "translate3d(" + this.shiftData.val + "px, 0, 0)"), this.shiftData.frameID = window.requestAnimationFrame(this.makeShiftStep)
    },
    onResize: function() {
            this.cycleLength = Math.ceil(this.$(".gga_gallery_img_wrapper").width() / 3)
    }
})

export default aboutGallery