import jQuery from "jquery";
import _ from "underscore";
import {View} from "backbone"; 
import baseThree from "./base/baseThree.js";

const topMenu =  View.extend({
    el: ".siteHeaderMenu",
    events: {
        "click .siteHeaderMenu-hamburger": "onHamburgerClick",
        "click .siteHeaderMenu-logo": "onLogoClick",
        "touchmove .siteHeaderMenu-popup": "onMenuTouchMove"
    },
    initialize: function(t) {
        _.bindAll(this, "onDocumentClick", "onPageLoad"),
         this.isMenuActive = !1, this.$el.css("display", ""),
          app.vent.on("document-click", this.onDocumentClick),
           app.vent.on("page-loaded", this.onPageLoad);
           baseThree.prototype.initialize.call(this, t)
    },
    onPageLoad: function() {
        this.$el.addClass("show")
    },
    onHamburgerClick: function() {
        // console.log("siteHeaderMenu-hamburger",this.$el,this.isMenuActive)
        jQuery("body").toggleClass("menu-open", !this.isMenuActive)
        this.$el.toggleClass("open", !this.isMenuActive), this.isMenuActive = !this.isMenuActive
    },
    onLogoClick: function() {
        jQuery("main").hasClass("Index") || (window.location.href = "/")
    },
    onMenuTouchMove: function(t) {
        t.preventDefault()
    },
    onDocumentClick: function(t) {
        this.isMenuActive && !jQuery(t).closest(".siteHeaderMenu").length && this.onHamburgerClick()
    }
})

export default topMenu