import _ from "underscore"
import jQuery from "jquery"
import baseThirteen from  "../../base/baseThirteen";


var newsView = baseThirteen.extend({
                el: ".News",
                events: {
                    "click .News-back-to-top": "onBackToTopClick"
                },
                initialize: function(t) {
                    //this.loadNews()
                    // console.log("newsView",this.$el)
                    if (this.$arrow = this.$(".News-back-to-top"), 
                    // this.footer = jQuery(".site_footer")[0], 
                    // this.footerBottom = jQuery(".Footer-bottom")[0],
                    _.bindAll(this, "onScroll", "onResizeInner"), 
                    jQuery(window).on("resize", this.onResizeInner), 
                    this.onResizeInner(), 
                    baseThirteen.prototype.initialize.call(this, t), 
                    this.$(".News-title").onCustom("screen-position", (function(t, e) {
                            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
                        })), 
                    this.$(".News-news-content-wrapper").onCustom("screen-position", (function(t, e) {
                            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
                        })), 
                    this.$(".Long-news-title").onCustom("screen-position", (function(t, e) {
                            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
                        })),
                    this.$(".News-img-wrapper").onCustom("screen-position", (function(t, e) {
                            e.screenPosition > 0 && e.$el.addClass("show").offCustom("screen-position")
                        })), 
                    jQuery(".News-invision-container").length && app.settings.isDesktop) {
                        var n = document.createElement("iframe");
                        n.title = "Invision", n.width = "100%", n.height = "870", n.src = "https://invis.io/J7S72JY8Z4E", jQuery(".News-invision-container").append(n)
                    }

                    function o(t) {
                        var e = t.target.dataset.block;
                        document.getElementById(e).scrollIntoView({
                            behavior: "smooth"
                        })
                    }
                    this.$(".link").each((function(t, e) {
                        e.addEventListener("click", o)
                    }))
                },
                // loadNews: function(){
                //     console.log(this.$el.find(".IndexNews-wrapper > ul"))
                //     this.$el.find(".IndexNews-wrapper > ul").append(`<div class="IndexNews-link">
                //     <a target="_self" href="http://localhost:3000/news">
                //         <span class="IndexNews-link-inner">
                //             <div class="IndexNews-link-line" style="">Everything You Need to Know</div>
                //             <div class="IndexNews-link-line" style="">About WordPress Websites in 2022</div>
                //         </span>
                //         <div class="IndexNews-imageContainer" style="transform: translateX(-16.78px);">
                //             <img src="assets/images/news/news-min_2.jpg" alt="" style="transform: translate(6.09648px, 21.4227px);">
                //         </div>
                //     </a>
                // </div>`)
                // },
                onResizeInner: function() {
                    // this.windowWidth = window.innerWidth, 
                    // this.windowHeight = window.innerHeight, 
                    // this.footerHeight = this.footer.offsetHeight, 
                    // this.footerBottomPadding = this.footerHeight - this.footerBottom.offsetTop - this.footerBottom.offsetHeight + (this.windowWidth < 666 ? 5 : 22), 
                    // this.arrowBottom = parseInt(this.$arrow.css("bottom")),
                    // this.arrowScrollShow = this.$(".News-content").width() / (this.windowWidth < 666 ? .9 : 2.6)
                },
                onScroll: function() {
                    if (!this.scrollingToTop) {
                        var t = r.prototype.onScroll.call(this),
                            e = this.footer.offsetTop,
                            n = e < t + this.windowHeight - this.arrowBottom,
                            i = t > this.arrowScrollShow,
                            o = Math.max(t - (e + this.footerHeight - this.windowHeight - this.footerBottomPadding + this.arrowBottom), 0);
                        n != this.prevIsDarkBg && this.$arrow.toggleClass("dark-bg", n), i != this.prevIsVisible && this.$arrow.toggleClass("visible", i), o != this.prevFixedMargin && this.$arrow.css("margin-bottom", o), this.prevIsDarkBg = n, this.prevIsVisible = i, this.prevFixedMargin = o
                    }
                },
                onBackToTopClick: function() {
                    window.pageYOffset;
                    this.scrollingToTop || (this.$arrow.removeClass("visible"), this.scrollingToTop = !0, jQuery("body, html").animate({
                        scrollTop: 0
                    }, 600, function() {
                        this.scrollingToTop = !1, this.onScroll()
                    }.bind(this)))
                }
            })

export default newsView;